<template>
  <div :class="$style.panel" class="bg-white dark:bg-neutral-950">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "neutral",
    },
  },
};
</script>

<style module>
.panel {
  @apply rounded-2xl shadow-2xl;
  padding: var(--ps-panel-padding, 20px);
}
</style>
