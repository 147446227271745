<template>
  <div
    :data-position="position"
    :data-active="isActive"
    ref="wrapper"
    class="group relative"
    :class="{
      [$style.entryDisplay]: true,
      [$style.entryDisplay_deleted]: entry.deleted_at,
      [$style.entryDisplay_selected]:
        position != 'draggable' && isSelected && !isDragged,
      [$style.entryDisplay_muted]: $store.getters.selected.length,
    }"
    :data-entry-id="entry.temp ? null : entry.id"
    :data-color="entry.color"
    @click="onClick"
    :tabindex="entry.temp ? -1 : 0"
    @contextmenu.prevent="contextMenu"
    @mousedown="onMouseDown"
    @mouseenter.stop="(e) => (!entry.temp ? onMouseEnter(e, DndInfo) : null)"
    @mouseleave.stop="(e) => (!entry.temp ? onMouseLeave(e) : null)"
    @touchstart="(e) => (!entry.temp ? onTouchStart(e) : null)"
    :style="cssProps"
    data-has-mouseenter="true"
  >
    <!-- v-memo="[
      isInitialized,
      isLazyMounted,
      // isVisibleInViewport,
      renderOutput,
      renderType,
      showConfig,
      JSON.stringify(display),
      JSON.stringify(entry),
      JSON.stringify(availableStatuses),
      JSON.stringify(customFields),
      configX,
      configY,
      $slots,
      isSelected,
      $store.getters.selected.length,
      isDragged,
      $route.params.id,
      renderDnDBeforeEdge,
      renderDnDAfterEdge,
    ]"  -->
    <div
      v-if="renderDnDBeforeEdge"
      data-dnd-edge="before"
      :class="[$style.dndEdge, $style.dndEdge_Before]"
    />
    <div
      v-if="renderDnDAfterEdge"
      data-dnd-edge="after"
      :class="[$style.dndEdge, $style.dndEdge_After]"
    />

    <!-- <ButtonComponent
      v-if="position == 'undo' && entry.deleted_at"
      color="red"
      class="mb-1.5 w-full"
    >
      <TrashIcon class="h-4 w-4" /> Deleted
    </ButtonComponent> -->
    <component
      :is="renderComponent"
      :output="output"
      :entry="entry"
      :computedDisplay="computedDisplay"
      data-entry-container
    >
      <template #cover>
        <CoverDisplay
          v-if="isLazyMounted && renderType == 'gallery' && entry.cover"
          :modelValue="entry.cover"
          :entry="entry"
          class="mb-2 h-24 w-full overflow-hidden rounded-lg"
        />
      </template>
      <template #id>
        <div class="whitespace-nowrap text-xs text-neutral-500">
          {{ entry.id }}
        </div>
      </template>
      <template #toggleOutput>
        <ButtonComponent
          @click.stop="
            (renderOutput = !renderOutput),
              toggleRenderOutput
                ? toggleRenderOutput(entry, renderOutput)
                : null
          "
          variant="minimal"
          size="sm"
          :color="entry.color"
          class="toggle_output rounded-full !ring-offset-2 !duration-0"
          data-test="toggle_output"
        >
          <ChevronRightIcon
            size="16"
            stroke-width="1.5"
            :class="{ 'rotate-90': renderOutput }"
            class="transition-transform duration-200"
          />
        </ButtonComponent>
      </template>
      <template #name>
        <NameDisplay
          v-if="isLazyMounted"
          ref="name_display"
          v-model="entry"
          :entry="entry"
          :placeholder="placeholder"
          :disabled="
            disabled ||
            position == 'calendar' ||
            (renderType == 'table' && !entry.temp) ||
            (renderType == 'board' && !entry.temp) ||
            position == 'template' ||
            (!entry.temp &&
              ((navigator.userAgent.includes('Mobile') &&
                $route.params.id != entry.id &&
                $route.params.id2 != entry.id) ||
                !['center', 'bound', 'modal'].includes(position)))
            // ||
            // $store.getters.dragged.length > 0
          "
          :class="{
            [`${$style.nameDisplay}`]: true,
            'mb-2': size == '4xl',
          }"
          @onKeyDown="
            (name, event) => {
              this.$emit('onNameKeyDown', name, event);
            }
          "
          @onKeyUp="
            (name, event) => {
              this.$emit('onNameKeyUp', name, event);
            }
          "
          @onEnter="
            () => {
              // this.$emit('onNameChanged', entry.name);
            }
          "
          @onFocus="() => $emit('onFocus')"
          @onBlur="() => $emit('onBlur')"
          :size="size"
          :position="position"
          :data-tour="
            position == 'center' && entry.id == $route.params.id
              ? 'entry_name'
              : null
          "
          :style="{
            '--ps-name-display-color': 'var(--ps-entry-display-color)',
          }"
        />
        <div
          v-else
          :class="[$style.skeletonLoader, $style.skeletonLoader_name]"
        />
      </template>
      <template #procrastination>
        <template
          v-if="computedDisplay.procrastination && entry.procrastination"
        >
          <ProcrastinationDisplay
            v-if="isLazyMounted"
            v-model="entry.procrastination"
            :entry="entry"
            :position="position"
            :class="$style.procrastinationDisplay"
          />

          <div
            v-else
            :class="[
              $style.skeletonLoader,
              $style.skeletonLoader_procrastination,
            ]"
          />
        </template>
      </template>
      <template #priority>
        <template
          v-if="
            computedDisplay.priority &&
            (entry.priority || renderType == 'table')
          "
        >
          <PriorityDisplay
            v-if="isLazyMounted"
            v-model="entry.priority"
            :entry="entry"
            :position="position"
            :class="$style.priorityDisplay"
          />
          <div
            v-else
            :class="[$style.skeletonLoader, $style.skeletonLoader_priority]"
          />
        </template>
      </template>
      <template #status>
        <template
          v-if="
            computedDisplay.status && (entry.status_id || renderType == 'table')
          "
        >
          <StatusDisplay
            v-if="isLazyMounted"
            v-model="status"
            :entry="entry"
            :statuses="availableStatuses"
            :disabled="disabled"
            :position="position"
            :size="size"
            @click.stop="(e) => $emit('click', e)"
            :class="$style.statusDisplay"
          />

          <div v-else>
            <div
              :class="[$style.skeletonLoader, $style.skeletonLoader_status]"
            />
          </div>
        </template>
      </template>
      <template #senses>
        <template v-if="computedDisplay.senses && entry.senses?.length">
          <SensesDisplay
            v-if="isLazyMounted"
            :class="{
              [`${$style.sensesDisplay}`]: true,
              // 'flex-col pb-1.5': position != 'left',
              // '-ml-1.5': position == 'left',
              // 'pt-2': [/*'lg', */ 'xl', '2xl', '4xl'].includes(size), // in case name is bigger
            }"
            class="_self-end"
            v-model="entry.senses"
            :entry="entry"
            :position="position"
            :disabled="disabled || position == 'calendar'"
          />
          <div v-else>
            <div
              :class="[$style.skeletonLoader, $style.skeletonLoader_senses]"
            />
          </div>
        </template>
      </template>
      <template #links>
        <template
          v-if="computedDisplay.links && (entry.links || renderType == 'table')"
        >
          <LinksDisplay
            v-if="isLazyMounted"
            v-model="entry"
            :entry="entry"
            :position="position"
            :class="{
              '-mt-0.5': ['sm'].includes(size) && position != 'calendar',
              'mt-0.5': ['md'].includes(size),
              'pt-1': ['lg', 'xl', '2xl'].includes(size), // in case name is bigger
              'pt-2': ['4xl'].includes(size), // in case name is bigger
              'order-last -mx-1 basis-full': renderType == 'board',
            }"
          />

          <div
            v-else
            :class="[$style.skeletonLoader, $style.skeletonLoader_links]"
          />
        </template>
      </template>
      <template #description>
        <slot name="description">
          <DescriptionDisplay
            v-if="
              isLazyMounted &&
              computedDisplay.description &&
              (entry.description || renderType == 'table')
            "
            :overrideEditable="renderType == 'table'"
            v-model="entry.description"
            :entry="entry"
            :position="position"
          />
        </slot>
      </template>
      <template #schedule>
        <template
          v-if="
            computedDisplay.schedule &&
            (entry.schedule || renderType == 'table')
          "
        >
          <ScheduleDisplay
            v-if="isLazyMounted"
            v-model="entry.schedule"
            :entry="entry"
            @click="
              (e) => $emit('click', e) // @note for input display to recognize the click event
            "
            :disabled="disabled"
          />
          <div
            v-else
            :class="[$style.skeletonLoader, $style.skeletonLoader_schedule]"
          />
        </template>
      </template>
      <template #time_trackings>
        <template
          v-if="
            computedDisplay.time_trackings &&
            !['gallery'].includes(renderType) &&
            (entry.time_trackings?.length ||
              (renderType == 'table' && !entry.temp))
          "
        >
          <TimeTrackingsDisplay
            v-if="isLazyMounted"
            v-model="entry.time_trackings"
            :entry="entry"
          />
          <div
            v-else
            :class="[
              $style.skeletonLoader,
              $style.skeletonLoader_timeTrackings,
            ]"
          />
        </template>
      </template>
      <template #routine>
        <template
          v-if="
            computedDisplay.routine && (entry.routine || renderType == 'table')
          "
        >
          <RoutineDisplay
            v-if="isLazyMounted"
            v-model="entry.routine"
            :entry="entry"
            :disabled="disabled"
            :class="{
              // 'ml-auto': true,
              // 'absolute bottom-2 right-2': renderType == 'gallery',
            }"
            class="ml-auto"
          />
          <div
            v-else
            :class="[$style.skeletonLoader, $style.skeletonLoader_routine]"
          />
        </template>
      </template>
      <template #custom_fields>
        <CustomFieldsDisplay
          v-if="
            isLazyMounted &&
            computedDisplay.custom_fields &&
            customFields?.length
          "
          :entry="entry"
          :customFields="customFields"
          class="mt-1"
        />
      </template>
      <template
        v-for="custom_field in customFields"
        v-slot:[`custom_field_${custom_field.id}`]
        :key="custom_field.id"
      >
        <CustomFieldsDisplay
          v-if="
            isLazyMounted &&
            computedDisplay.custom_fields &&
            customFields?.length
          "
          :entry="entry"
          :customFields="[custom_field]"
          class="mt-1"
        />
      </template>
      <template #settings>
        <slot v-if="isLazyMounted" name="settings">
          <button
            v-if="
              !(
                ($route.params.id == entry.id && position == 'center') ||
                ($route.params.id2 == entry.id && position == 'modal')
              ) &&
              computedDisplay.settings &&
              !entry.temp &&
              ($route.params.id != entry.id || position == 'left')
            "
            type="button"
            @click.stop="showConfig = true"
            :class="{
              'absolute top-[2px] z-10 opacity-0 focus-visible:bg-white focus-visible:opacity-100 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-neutral-900 group-hover:md:opacity-100 dark:focus-visible:bg-black dark:focus-visible:ring-neutral-100':
                position == 'left',

              // 'text-neutral-200 hover:!bg-white/10 focus-visible:bg-neutral-900 focus-visible:ring-neutral-200 dark:text-neutral-700  dark:focus-visible:bg-neutral-200':
              //   position == 'left' && isActive,
              'mt-0.5': position != 'left',
              'mt-2': ['4xl'].includes(size), // in case name is bigger
            }"
            class="ps_settings_button right-0 flex w-5 justify-center rounded-full text-gray-700 hover:bg-black/5 dark:text-neutral-400 dark:hover:bg-white/5"
            data-table-column-target
            data-test="entry_settings"
          >
            <EllipsisIcon size="20" strokeWidth="1.5" />
          </button>
        </slot>
        <div
          v-else
          :class="[$style.skeletonLoader, $style.skeletonLoader_settings]"
        />
      </template>
      <template #created_at>
        <div
          v-if="!entry.temp"
          class="whitespace-nowrap text-xs text-neutral-500"
        >
          {{ entry.created_at }}
        </div>
      </template>
      <template #updated_at>
        <div
          v-if="!entry.temp"
          class="whitespace-nowrap text-xs text-neutral-500"
        >
          {{ entry.updated_at }}
        </div>
      </template>
    </component>

    <Teleport v-if="isLazyMounted" :disabled="!showConfig" to="body">
      <OverlayComponent
        v-if="showConfig"
        @click.stop="showConfig = false"
        data-test="contextmenu_overlay"
      />
      <Transition
        enter-active-class="transition-opacity duration-100"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="showConfig"
          ref="configForm"
          @submit.prevent
          :style="{
            left: configX + 'px',
            top: configY + 'px',
          }"
          class="fixed max-h-[500px] max-w-fit cursor-auto"
        >
          <EntryContextmenu
            ref="configuration"
            v-model="entry"
            :hide="['timestamps']"
            @mouseup.stop
            @mousedown.stop
            @touchstart.stop
            @touchend.stop
            @close="showConfig = false"
            :shareNode="$refs.wrapper"
            :position="position"
          >
            <slot name="contextmenu" />
          </EntryContextmenu>
        </div>
      </Transition>
    </Teleport>

    <!-- Start: Draggable Placeholder -->
    <div
      v-if="
        // $store.getters.dragged.findIndex((e) => e.id == entry.id) !== -1 &&
        position != 'draggable' && position != 'calendar' && position != 'undo'
      "
      :class="$style.dragPlaceholder"
      data-draggable-placeholder
    />
    <!-- End: Draggable Placeholder -->
  </div>
</template>
<!-- eslint-disable vue/no-unused-components -->
<script>
import { Applicators } from "../mixins/Applicators";
import { DragAndDrop } from "../mixins/DragAndDrop";
import { Filtering } from "../mixins/Filtering";
import { Grouping } from "../mixins/Grouping";
import { Routining } from "../mixins/Routining";
import { Scheduling } from "../mixins/Scheduling";
import { Schema } from "../mixins/Schema";
import { Sorting } from "../mixins/Sorting";
import { Statusing } from "../mixins/Statusing";
import { Routing } from "../mixins/Routing";
// import { Lazying } from "../mixins/Lazying";
// import { Viewporting } from "../mixins/Viewporting";

import EntryContextmenu from "./partials/EntryContextmenu.vue";
import { ChevronRightIcon, EllipsisIcon } from "lucide-vue-next";
import { defineAsyncComponent, hydrateOnIdle } from "vue";

export default {
  inject: {
    overrideEntries: {
      default: null,
    },
    output_entry_origin: {
      default: null,
    },
  },
  // eslint-disable-next-line vue/no-unused-components
  components: {
    EntryContextmenu,
    ChevronRightIcon,
    EllipsisIcon,
    SensesDisplay: defineAsyncComponent({
      loader: () => import("@/components/senses/SensesDisplay.vue"),
      hydrate: hydrateOnIdle,
    }),
    EntryDisplayList: defineAsyncComponent({
      loader: () => import("@/components/entry/partials/EntryDisplayList.vue"),
      hydrate: hydrateOnIdle,
    }),
    EntryDisplayTable: defineAsyncComponent({
      loader: () => import("@/components/entry/partials/EntryDisplayTable.vue"),
      hydrate: hydrateOnIdle,
    }),
  },
  mixins: [
    Schema,
    Filtering,
    Grouping,
    Sorting,
    Statusing,
    DragAndDrop,
    Scheduling,
    Routining,
    Applicators,
    Routing,
    // Lazying,
    // Viewporting,
  ],
  props: {
    modelValue: Object,
    output: Object,
    placeholder: String,
    position: String,
    display: Object,
    disabled: Boolean,
    renderType: {
      type: String,
      default: "list",
    },
    disableRouting: Boolean,
    containingOutputGroup: String,
    size: {
      type: String,
      default: "md",
    },
    toggleRenderOutput: Function,
    isRenderOutput: Boolean,
    entryIndex: Number,
  },

  data() {
    return {
      isInitialized: true,
      isVisibleInViewport: false,
      dataDisplay: {},
      defaultDisplay: {
        name: true,
        status: true,
        procrastination: true,
        description: true,
        time_trackings: true,
        priority: true,
        routine: true,
        senses: true,
        schedule: true,
        output: true,
        input: true,
        links: true,
        columns: true,
        leftover: true,
        settings: true,
        custom_fields: true,
      },
      navigator: navigator,
      showOutput: false,
      showConfig: false,
      configX: null,
      configY: null,

      // entries: [],
      // children: [],

      availableStatuses: [],
      customFields: [],
      renderOutput: this.isRenderOutput,
      isSelected: false,
      isDragged: false, // $store.getters.dragged.findIndex((e) => e.id == entry.id) === -1
      isLazyMounted: false,
    };
  },
  mounted() {
    // console.log("create->mounted: " + this.modelValue.name);
    this.dataDisplay = this.display;
    this.availableStatuses = this.availableStatusesComputed;
    this.customFields = this.customFieldsComputed;
    // setTimeout(() => {
    this.pullData();
    // }, 1000);
    // this.$onIdle(() => {
    requestAnimationFrame(() => {
      this.isLazyMounted = true;
    });
  },

  // eslint-disable-next-line no-unused-vars
  // renderTracked(event) {
  //   console.trace();
  //   console.log("renderTracked e", this.entry.name, this.position, event);
  // },
  // // eslint-disable-next-line no-unused-vars
  // renderTriggered(event) {
  //   // console.trace();
  //   console.log("renderTriggered e", this.entry.name, this.position, event);
  // },
  computed: {
    renderComponent() {
      switch (this.renderType) {
        case "table":
          return "EntryDisplayTable";
        case "list":
        default:
          return "EntryDisplayList";
      }
    },
    computedDisplay() {
      return {
        ...this.defaultDisplay,
        ...this.dataDisplay,
      };
    },
    entry: {
      get() {
        return this.modelValue;
      },
      set() {
        //this.$emit("update:modelValue", value);
      },
    },

    space() {
      return this.$store.getters.space;
    },
    // entries() {
    //   return this.$store.getters.entries.filter((e) => e.deleted_at === null);
    // },
    // children() {
    //   return this.filterEntries(this.outputSchema(this.entry))
    //     .filter(this.filterGroups(this.entry.output?.grouping))
    //     .sort(this.sortEntries(this.entry.output?.sorting));
    // },
    status: {
      get() {
        return this.getStatusById(this.entry.status_id);
      },
      set(status) {
        this.setStatus(this.entry, status);
        if (!this.entry.temp)
          this.$store.dispatch("push", {
            event: "entry_update",
            params: { entry: this.entry },
            entry: this.entry,
          });
      },
    },
    customFieldsComputed() {
      return this.entry.links
        ?.map((id) => this.$store.getters.entries.find((e) => e.id == id))
        .filter((e) => e?.custom_fields?.length)
        .flatMap((e) => e.custom_fields);
    },
    availableStatusesComputed() {
      if (this.overrideStatuses) return this.overrideStatuses;

      return this.$merge(
        this.getAvailableStatuses(this.entry),
        this.output_entry_origin &&
          JSON.stringify(this.getAvailableStatuses(this.entry)) !=
            JSON.stringify(this.getAvailableStatuses(this.output_entry_origin))
          ? this.getAvailableStatuses(this.output_entry_origin)
          : [],
      );
    },
    isActive() {
      return this.entry.id == this.$route.params.id;
    },
    cssProps() {
      return {
        "--ps-entry-display-color": this.entry.color
          ? "var(--ps-color-" + this.entry.color + "-500)"
          : null,
      };
    },
  },
  methods: {
    pullData() {
      if (this.entry.output) {
        // this.$nextTick(() => {
        this.$store.dispatch("pull", {
          filters: this.entry.output.filters,
          source: {
            file: "EntryDisplay",
            entry: this.entry.name,
          },
        });
        // });
      }
    },
    onClick(e) {
      if (process.env.NODE_ENV === "development" && e.altKey) {
        this.isLazyMounted = !this.isLazyMounted;
        return;
      }

      if (this.disableClick) return;
      if (!this.entry.temp) {
        if (
          this.$route.params.id != this.entry.id &&
          this.$route.params.id2 != this.entry.id &&
          (e.shiftKey ||
            e.ctrlKey ||
            (navigator.userAgent.includes("Mobile") &&
              this.$store.getters.selected.length > 0))
        ) {
          // Bulk Selection
          this.$store.dispatch("selected", this.entry);
          return;
        }
        if (e.altKey) {
          // Open in new tab
          window.open(window.location.origin + "/" + this.entry.id, "_blank");
          return;
        }

        if (
          /*(!this.showOutput || this.position == "left") &&*/
          !this.disableRouting &&
          !window.getSelection().toString().length
        ) {
          this.navigateTo(this.entry);
        }
      }
    },

    contextMenu(e, settingsClicked = false) {
      if (this.entry.temp) {
        return;
      }

      if (this.$route.params.id == this.entry.id && this.position == "center") {
        return;
      }

      /**
       * pointerType is undefined in cypress e2e tests
       */
      if (e.pointerType == "mouse" || typeof e.pointerType == "undefined") {
        if (e.type == "contextmenu" || settingsClicked) {
          /**
           * stopPropagation is needed to
           * prevent the contextmenu event
           * from potential parent entry
           */
          e.stopPropagation();
          e.preventDefault();

          if (this.computedDisplay.settings) {
            this.mousePositionOnDragStart.x = null;
            this.mousePositionOnDragStart.y = null;

            this.showConfig = !this.showConfig;
          }
        }
      } else {
        // no contextmenu on touch devices
        // if (this.showConfigOnPress == this.showConfig)
        //   this.showConfig = !this.showConfig;
        // e.stopPropagation();
      }
    },
    updateEntry() {
      this.$store.dispatch("push", {
        event: "entry_update",
        params: { entry: this.entry },
        entry: this.entry,
      });
    },
    animationEnterContextmenu(el, done) {
      this.$anime({
        targets: el,
        opacity: [0, 1],
        translateY: [20, 0],
        complete: done,
      });
    },
  },
  watch: {
    display: {
      handler: function (n, o) {
        if (JSON.stringify(n) != JSON.stringify(o)) {
          this.dataDisplay = n;
        }
      },
      immediate: true,
    },
    "$store.getters.selected": function (n) {
      const isSelected = n.findIndex((id) => id == this.entry.id) !== -1;
      if (isSelected != this.isSelected) this.isSelected = isSelected;
    },
    "$store.getters.dragged": function (n) {
      const isDragged = n.findIndex((e) => e.id == this.entry.id) !== -1;
      if (isDragged != this.isDragged) this.isDragged = isDragged;
    },
    availableStatusesComputed: function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) this.availableStatuses = n;
    },
    customFieldsComputed: function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) this.customFields = n;
    },

    "$store.getters.timestamp": function (n, o) {
      if (n - o > 2) {
        this.$onIdle(() => {
          this.pullData();
        });
      }
    },
    showConfig() {
      if (this.showConfig) {
        this.configX = this.$cursorPosition.x;
        this.configY = this.$cursorPosition.y;

        this.$nextTick(() => {
          /**
           * This is used to prevent the config
           * form from overlapping on the right side
           */
          if (
            window.innerWidth <
            this.configX + this.$refs.configForm.offsetWidth
          ) {
            this.configX =
              window.innerWidth - this.$refs.configForm.offsetWidth - 20;
          }
          /**
           * This is used to prevent the config
           * form from overlapping on the bottom side
           */

          if (
            window.innerHeight <
            this.configY + this.$refs.configForm.offsetHeight
          ) {
            this.configY =
              window.innerHeight -
              this.$refs.configForm.offsetHeight -
              window.innerHeight / 10;
          }

          this.configX = Math.max(5, this.configX);
          this.configY = Math.max(5, this.configY);
        });
      }
    },
  },
};
</script>

<style module lang="scss">
.entryDisplay {
  padding: var(--ps-entry-display-padding-y) var(--ps-entry-display-padding-x);
  border-radius: var(--ps-entry-display-border-radius);

  -webkit-touch-callout: none; /* Safari */
  -webkit-user-select: none; /* Chrome */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  &_selected {
    @apply ring-1 ring-inset ring-blue-500;
  }
  &_muted {
    > * {
      @apply pointer-events-none;
    }
  }
  &_deleted {
    @apply pointer-events-none;
  }
}

.procrastinationDisplay,
.priorityDisplay {
  padding-top: max(0px, var(--ps-name-display-line-height) / 2 - 12px);
  // @apply ring;
  // line-height: var(--ps-name-display-line-height);
}

.skeletonLoader {
  composes: skeletonLoader from "@/styles/skeleton-loader.module.scss";
  --ps-skeleton-loader-name-height: 24px;
  &_status {
    width: 20px;
    height: 20px;
    margin-top: 2px;
    margin-left: -2px;
    margin-right: -4px;
    border-radius: 100%;
  }
  &_senses {
    height: 16px;
    width: 16px;
  }
  &_priority,
  &_procrastination {
    height: var(--ps-skeleton-loader-name-height);
    flex: 0;
    min-width: 32px;
  }
  &_name {
    height: var(--ps-skeleton-loader-name-height);
    flex: 1 1 50%; // ? This is to make sure the name is wider than the links in the skeleton loader
  }
  &_links {
    height: calc(var(--ps-skeleton-loader-name-height) * 0.75);
    margin-top: calc(var(--ps-skeleton-loader-name-height) * 0.125);
  }
  &_schedule {
    margin-top: 2px;
    max-width: 150px;
    height: 18px;
  }
  &_timeTrackings {
    margin-top: 2px;
    max-width: 150px;
    height: 18px;
  }
  &_routine {
    margin-top: 2px;
    max-width: 150px;
    height: 22px;
    margin-left: auto;
    max-width: 250px;
  }

  &_settings {
    border-radius: 100%;
    margin-top: 2px;
    height: 20px;
    width: 20px;
  }
}

.dndEdge {
  @apply absolute z-50;
  background-color: var(--ps-base-primary-color);
  pointer-events: none;

  width: var(--ps-dnd-edge-width);
  height: var(--ps-dnd-edge-height);

  &:before,
  &:after {
    @apply absolute h-2.5 w-2.5  rounded-full;
    content: "";
    background-color: var(--ps-base-primary-color);
    border: 1px solid var(--ps-base-background-color);
  }
  &:before {
    top: var(--ps-dnd-dots-before-top);
    right: var(--ps-dnd-dorts-before-right);
    bottom: var(--ps-dnd-dots-before-bottom);
    left: var(--ps-dnd-dots-before-left);
    transform: translate(
      var(--ps-dnd-dots-before-translate-x, 0px),
      var(--ps-dnd-dots-before-translate-y, 0px)
    );
  }
  &:after {
    top: var(--ps-dnd-dots-after-top);
    right: var(--ps-dnd-dots-after-right);
    bottom: var(--ps-dnd-dots-after-bottom);
    left: var(--ps-dnd-dots-after-left);
    transform: translate(
      var(--ps-dnd-dots-after-translate-x, 0px),
      var(--ps-dnd-dots-after-translate-y, 0px)
    );
  }
  &_Before {
    top: var(--ps-dnd-edge-before-top);
    right: var(--ps-dnd-edge-before-right);
    bottom: var(--ps-dnd-edge-before-bottom);
    left: var(--ps-dnd-edge-before-left);

    transform: translate(
      calc(
        var(--ps-dnd-edge-before-translate-x, 0px) - var(--ps-dnd-offset, 0px)
      ),
      var(--ps-dnd-edge-before-translate-y, 0)
    );
  }
  &_After {
    top: var(--ps-dnd-edge-after-top);
    right: var(--ps-dnd-edge-after-right);
    bottom: var(--ps-dnd-edge-after-bottom);
    left: var(--ps-dnd-edge-after-left);

    transform: translate(
      calc(
        var(--ps-dnd-edge-after-translate-x, 0px) + var(--ps-dnd-offset, 0px)
      ),
      var(--ps-dnd-edge-after-translate-y, 0)
    );
  }
}

.nameDisplay {
  @apply w-full flex-1;
  min-width: 40%;
}

.dragPlaceholder {
  position: absolute;
  inset: 0px;
  z-index: 40;
  cursor: default;
  border-radius: var(--ps-entry-display-border-radius);
  display: none;
  background-color: var(--ps-base-tinted-color);
}
</style>
