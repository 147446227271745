export const Applicators = {
  methods: {
    applyableInputs(entry) {
      if (entry.temp || !this.inputs) return [];

      var string = JSON.stringify(entry);
      return this.inputs.filter((input) => {
        var clone = JSON.parse(string);
        clone = this.applyInput(
          this.inputSchema(
            input, clone
          ),
          clone
        );
        return string != JSON.stringify(clone);
      });
    },
    inputApplyable(input, entry) {
      const before = JSON.stringify(entry);
      const after = JSON.stringify(this.applyInput(this.inputSchema(input, entry), JSON.parse(before)));

      return before != after;
    },
    applyInput(input, entry = null, save = false) {
      // console.log("APPLY INPUT", input, entry.name);
      const clone = JSON.stringify(entry);

      // eslint-disable-next-line no-unreachable
      if (input.schedule) {
        if (
          entry.schedule &&
          entry.schedule.id &&
          input.schedule &&
          input.schedule.id
        )
          input.schedule.id = entry.schedule.id;

        this.setEntrySchedule(entry, input.schedule);
      }
      if (input.status_id) {
        this.setStatus(entry, input.status_id);
      }

      for (const [key, value] of Object.entries(input)) {
        switch (key) {
          // case "completion":
          // case "status":
          case "anchor":
          case "output":
          case "input":
          case "priority":
          case "routine":
          case "description":
            if (entry[key] && entry[key].id && value && value.id)
              value.id = entry[key].id;
            entry[key] = { ...entry[key], ...value };
            break;
          case "links":
          case "custom_fields":
          case "statuses":
          case "senses":
          case "color":
            entry[key] = value;
            break;
          case "time_trackings":
            if (value && value.length && value[0].start_at && value[0].start_at?.op && value[0].start_at?.op == 'now') {
              this.startTimeTracking(entry);
            }
            break;
        }
      }
      // console.log(clone, JSON.stringify(entry));
      if (save && clone != JSON.stringify(entry)) {

        // we need to keep the input_id if it exists, because it's used for the schema
        if (input.input_id) {
          entry.input_id = input.input_id;
        }
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: entry },
          entry: entry,
          source: "Applicator"
        });
      }
      return entry;
    },
  },
};
