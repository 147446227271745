<template>
  <div id="application-footer" :class="$style.applicationFooter">
    <div
      :class="{
        'shadow-lg ring-1 ring-inset ring-neutral-100 dark:shadow-none dark:ring-neutral-700':
          mainFrame &&
          mainFrame.scrollHeight - $window.innerHeight - scrollY > 15,
      }"
      class="pointer-events-auto max-w-fit -translate-x-2 rounded-full bg-white/75 backdrop-blur transition-all dark:bg-black/75"
    >
      <div class="flex items-center gap-x-4 p-2 sm:hidden">
        <ButtonComponent
          ref="left_sidebar_toggle_mobile"
          id="left_sidebar_toggle_mobile"
          variant="round"
          color="secondary"
          size="lg"
          @click="$emit('toggleSidebarMobile')"
          data-test="left_sidebar_toggle_mobile"
        >
          <span class="sr-only">Open sidebar</span>
          <IconHelper name="panel-left" size="24" />
        </ButtonComponent>

        <!-- Separator -->
        <div
          class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200 dark:lg:bg-gray-800"
          aria-hidden="true"
        />

        <ButtonComponent
          v-if="anchors.length"
          variant="round"
          color="secondary"
          size="lg"
          @click="
            () => {
              $router.push({
                name: 'entry',
                params: { id: anchors[0].id },
              });
            }
          "
        >
          <span class="sr-only">Go home</span>
          <IconHelper name="home" size="24" />
        </ButtonComponent>

        <ButtonComponent
          @click="$emit('toggleCommandPalette')"
          variant="round"
          color="secondary"
          size="lg"
        >
          <IconHelper name="search" size="24" />
        </ButtonComponent>
      </div>
    </div>

    <Transition
      enter-active-class="transition-all duration-[800ms] ease-spring"
      enter-from-class="opacity-0 translate-y-24"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition-all duration-[800ms] ease-spring"
      leave-to-class="opacity-0 translate-y-24"
    >
      <div
        v-if="entry && inputs.length"
        :class="{
          'shadow-lg ring-1 ring-inset ring-neutral-100 dark:shadow-none dark:ring-neutral-700':
            mainFrame &&
            mainFrame.scrollHeight - $window.innerHeight - scrollY > 15,
        }"
        class="pointer-events-auto max-w-fit translate-x-2 rounded-full bg-white/75 backdrop-blur transition-all dark:bg-black/75"
      >
        <div class="flex h-14 items-center p-2">
          <MenuHelper placement="top-start">
            <template #button>
              <div ref="listbox_button" class="pointer-events-none leading-[0]">
                <ButtonComponent
                  @click.stop
                  @mouseenter="onMouseEnter"
                  @mouseleave="onMouseLeave"
                  @mousedown="onMouseDown"
                  v-touch:press.stop="onMouseDown"
                  @mouseup="onMouseUp"
                  v-touch:release.stop="onMouseUp"
                  variant="round"
                  class="pointer-events-auto !bg-transparent !p-0"
                  :title="`Add Entry to ${inputs[0].entry.name || 'Unnamed'}`"
                  shortcode="+"
                >
                  <div
                    ref="button"
                    :class="`bg-${
                      entry.color ? entry.color + '-500' : 'neutral-900'
                    }`"
                    class="h-full w-full rounded-full p-2 transition-colors duration-500 dark:ring-1 dark:ring-neutral-700"
                  >
                    <IconHelper name="plus" size="24" />
                    <!-- <PlusIcon
                    class="absolute left-1/2 top-1/2 h-6 w-6 -translate-x-1/2 -translate-y-1/2"
                  /> -->
                  </div>
                </ButtonComponent>
              </div>
            </template>
            <MenuItem
              v-for="(input, key) in inputs.slice(0, inputs.length)"
              :key="key"
              v-slot="{ active }"
            >
              <a
                @click.stop="focusInput(input)"
                :active="active"
                :class="$style.menuItem"
              >
                <EntryDisplay
                  :modelValue="
                    inputSchemaWithId(input.schema, input.entry.name)
                  "
                  :disabled="true"
                  size="xs"
                />
              </a>
            </MenuItem>
          </MenuHelper>
        </div>
      </div>
    </Transition>
  </div>
</template>
<!-- eslint-disable no-unused-vars -->
<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  Bars3Icon,
  UserIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/vue/24/outline";
import anime from "animejs";
import { PanelLeftIcon, HomeIcon, SearchIcon, PlusIcon } from "lucide-vue-next";
</script>

<!-- eslint-disable vue/no-unused-components -->
<script>
import { Schema } from "./mixins/Schema";
import { Statusing } from "./mixins/Statusing";
import { Scheduling } from "./mixins/Scheduling";
export default {
  props: {
    entry: Object,
    scrollY: Number,
  },
  mixins: [Schema, Statusing, Scheduling],
  data() {
    return {
      // scrollY: 0,
      mouseDown: false,
      interval: null,
      timeout: null,
      mainFrame: null,
      // input: null,
    };
  },
  watch: {
    // entry: {
    //   handler() {
    //     this.detectInput();
    //   },
    //   deep: true,
    // },
    // "entries.length": function () {
    //   this.detectInput();
    // },
    // "$store.getters.initialized": function () {
    //   this.detectInput();
    // },
    "draggedEntries.length": function (n) {
      if (n > 0) {
        window.addEventListener("touchmove", this.onTouchMove);
      } else {
        window.removeEventListener("touchmove", this.onTouchMove);
      }
    },
  },
  computed: {
    inputs() {
      return this.$store.getters.registeredInputs.filter(
        (input) => input.position == "center",
      );
    },
    entries() {
      return this.$store.getters.entries.filter((e) => e.deleted_at === null);
    },
    linkedEntries() {
      return this.entry.links
        .map((id) => this.entries.find((e) => e.id == id))
        .filter((e) => e);
    },
    anchors() {
      return this.entries
        ?.filter((e) => e.anchor)
        .sort((a, b) => {
          if (a.anchor.position > b.anchor.position) return 1;
          if (a.anchor.position < b.anchor.position) return -1;
          return 0;
        });
    },
    draggedEntries() {
      return this.$store.getters.dragged;
    },
  },
  mounted() {
    this.mainFrame = document.getElementById("main");
    // if (this.mainFrame)
    //   this.mainFrame.addEventListener("scroll", this.onScroll);
    // this.onScroll();
    this.$nextTick(() => {
      this.$emit("mounted");
    });

    window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("keyup", this.onKeyUp);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
    window.removeEventListener("keyup", this.onKeyUp);
    // if (this.mainFrame)
    //   this.mainFrame.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onKeyDown(event) {
      if (event.key == "+" && !event.repeat && (event.target.tagName !== "INPUT" &&
              event.target.isContentEditable !== true) && !this.isElementObscured(this.$refs.button)) {
        this.$refs.button.dispatchEvent(new MouseEvent("mousedown", { bubbles: true }));
      }
    },
    onKeyUp(event) {
      if (event.key == "+" && !event.repeat && (event.target.tagName !== "INPUT" &&
              event.target.isContentEditable !== true) && !this.isElementObscured(this.$refs.button)) {
        this.$refs.button.dispatchEvent(new MouseEvent("mouseup", { bubbles: true }));
      }
    },

    isElementObscured(element) {
      const rect = element.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      const topElement = document.elementFromPoint(centerX, centerY);
      return topElement !== element && !element.contains(topElement);
    },
    focusInput(input) {
      if (navigator.userAgent.includes("Mobile") && this.mainFrame)
        this.mainFrame.style.overflowY = "hidden";

      input.obj.$refs.entry_display.isInitialized = true;
      this.$nextTick(() => {
        input.el.querySelector("[contenteditable]").click();
        this.$nextTick(() => {
          input.el
            .querySelector("[contenteditable]")
            .focus({ preventScroll: true });
          setTimeout(() => {
            if (navigator.userAgent.includes("Mobile") && this.mainFrame)
              this.mainFrame.style.overflowY = "auto";
            input.el.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
          }, 100);
        });
      });
    },

    onMouseEnter() {
      anime({
        targets: this.$refs.button,
        scale: 1.25,
      });
    },
    onMouseLeave() {
      anime({
        targets: this.$refs.button,
        scale: 1,
      });
    },
    onMouseDown(e) {
      // console.log("onMouseDown", e);
      anime({
        targets: this.$refs.button,
        scale: 1.5,
      });

      // console.log("onMouseDown", e);
      e.stopPropagation();
      if (e.type != "touchstart") e.preventDefault();
      // if (e.button === 0) {
      this.mouseDown = true;
      this.timeout = setTimeout(() => {
        if (this.mouseDown) {
          this.mouseDown = false;

          anime({
            targets: this.$refs.button,
            scale: 1,
          });

          this.$refs.listbox_button.click();
          this.timeout = null;
        }
      }, 500);
      // }
    },
    onMouseUp(e) {
      anime({
        targets: this.$refs.button,
        scale: 1,
      });
      // console.log("onMouseUp", e);
      e.stopPropagation();
      e.preventDefault();
      // if (e.button === 0) {
      this.mouseDown = false;
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.focusInput(this.inputs[0]);
      } else {
        clearTimeout(this.timeout);
      }

      this.timeout = null;
      // }
    },
    onTouchMove(e) {
      if (e.touches && e.touches.length > 0) {
        e.preventDefault();
        e.stopPropagation();

        const touch = e.touches[0];
        const el = document.elementFromPoint(touch.clientX, touch.clientY);
        if (el) {
          const button = el.closest("#left_sidebar_toggle_mobile");
          if (button) {
            this.$emit("toggleSidebarMobile");
          }
        }
      }
    },

    // onScroll() {
    //   this.scrollY = this.mainFrame.scrollTop;
    // },
    inputSchemaWithId(schema, name) {
      const base = {
        temp: true,
        id: this.$nanoid(),
        space_id: this.space?.id || this.$store.getters.user?.current_space_id,
        status_id: null,
        name: name || "Unnamed",
        statuses: [],
        custom_fields: [],
        custom_values: [],
        time_trackings: [],
        senses: [],
        links: [],
        backlinks: [],
        created_at: this.$moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        updated_at: this.$moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        completed_at: null,
        deleted_at: null,
      };
      return {
        ...base,
        ...this.inputSchema(schema, base),
      };
    },
  },
};
</script>

<style module lang="scss">
.applicationFooter {
  @apply pointer-events-none flex items-center justify-between;
  -webkit-touch-callout: none; /* Safari */
  -webkit-user-select: none; /* Chrome */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  -webkit-overflow-scrolling: auto;

  --ps-name-display-font-size: var(--ps-font-size-sm);
  --ps-name-display-line-height: var(--ps-line-height-sm);
}

.menuItem {
  @apply block cursor-pointer border-b;
  border-bottom-color: var(--ps-base-border-color);
  &:hover {
    background-color: var(--ps-base-tinted-color);
  }
}
</style>
