<template>
  <SwitchGroup as="div" class="flex items-center justify-between gap-1.5">
    <span class="flex grow flex-col">
      <SwitchLabel as="span" :class="$style.label" passive>
        {{ label }}
      </SwitchLabel>
      <!-- <SwitchDescription as="span" class="text-sm text-gray-500"
        >Nulla amet tempus sit accumsan. Aliquet turpis sed sit
        lacinia.</SwitchDescription
      > -->
    </span>
    <Switch
      :modelValue="modelValue"
      @update:modelValue="$emit('update:modelValue', !modelValue)"
      :class="[
        modelValue ? $style.toggleEnabled : $style.toggleDisabled,
        $style.toggle,
      ]"
    >
      <span
        aria-hidden="true"
        :class="[
          modelValue ? 'translate-x-3.5' : 'translate-x-0.5',
          'pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        ]"
      />
    </Switch>
  </SwitchGroup>
</template>

<script>
import {
  Switch,
  //   SwitchDescription,
  SwitchGroup,
  SwitchLabel,
} from "@headlessui/vue";

export default {
  components: {
    Switch,
    // SwitchDescription,
    SwitchGroup,
    SwitchLabel,
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    modelValue: Boolean,
  },
};
</script>

<style module>
.label {
  @apply text-xs font-medium;
  color: var(--ps-toggle-color, var(--ps-base-secondary-color));
}
.toggle {
  @apply relative inline-flex h-5 w-8 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2;
  --tw-ring-opacity: 1;
}
.toggleEnabled {
  --tw-ring-color: var(--ps-toggle-color, var(--ps-base-secondary-color));
  background-color: var(--ps-toggle-color, var(--ps-base-secondary-color));
}

.toggleDisabled {
  --tw-ring-color: var(--ps-base-tertiary-color);
  background-color: var(--ps-base-tertiary-color);
}
</style>
