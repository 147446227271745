<template>
  <Dialog
    as="div"
    v-if="dialogIsOpen"
    :open="true"
    class="relative h-px w-px"
    @close="
      // $console.trace();
      // $console.log('close');
      _trackEvent('Navigation', 'Entry Modal', 'Close Modal'),
        $router.replace({
          name: 'entry',
          params: { id: $route.params.id },
        })
    "
    :style="cssProps"
  >
    <Transition
      enter-active-class="transition-opacity duration-200 ease-linear"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-100 ease-linear"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      appear
    >
      <div v-if="entry_by_route" class="fixed inset-0 backdrop-blur" />
    </Transition>
    <Transition
      enter-active-class="transition-all duration-300 ease-out"
      enter-from-class="opacity-0 translate-y-10"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition-all duration-200 ease-out"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-10"
      appear
    >
      <div
        v-if="entry_by_route"
        ref="wrapper"
        id="entry-modal-page-wrapper"
        class="fixed inset-0 w-screen overflow-y-auto overflow-x-hidden"
        v-touch:press="
          () => {
            scrollingIsMade = false;
            scrollTopOnPress = $refs.wrapper.scrollTop;
          }
        "
        @scroll="scrollingIsMade = true"
        v-touch:release="
          () => {
            if (
              scrollingIsMade &&
              scrollTopOnPress === 0 &&
              $refs.wrapper.scrollTop <= 0
            ) {
              _trackEvent('Navigation', 'Entry Modal', 'Close Modal'),
                $router.go(-1);
            }
          }
        "
        v-touch:swipe.bottom="
          () => {
            if ($refs.wrapper.scrollTop === 0) {
              _trackEvent('Navigation', 'Entry Modal', 'Close Modal'),
                $router.go(-1);
            }
          }
        "
      >
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <DialogPanel
            class="relative max-w-[90vw] transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-2 text-left shadow-xl ring-1 ring-transparent transition-all sm:my-8 sm:w-full sm:px-6 sm:pb-6 md:max-w-3xl lg:max-w-5xl dark:bg-black dark:ring-neutral-700"
          >
            <DialogTitle
              as="h3"
              class="sticky top-0 z-10 -mx-6 rounded-b bg-white px-6 py-4 dark:bg-black"
            >
              <BulkSelectMenu v-if="$store.getters.selected.length" size="xs" />
              <div
                v-else
                class="flex min-h-9 justify-between text-base font-semibold leading-6 text-gray-900 dark:bg-black"
              >
                <div class="flex items-center gap-2">
                  <a
                    @click="
                      _trackEvent('Navigation', 'Entry Modal', 'Close Modal'),
                        $router.replace({
                          name: 'entry',
                          params: { id: $route.params.id },
                        })
                    "
                    class="cursor-pointer text-xs"
                  >
                    {{
                      entry_by_route_before?.name.length > 32
                        ? entry_by_route_before.name.slice(0, 32) + "..."
                        : entry_by_route_before.name
                    }}
                  </a>
                  <ChevronRightIcon size="16" aria-hidden="true" />
                  <a @click="openFullPage" class="cursor-pointer text-xs">
                    {{
                      entry_by_route.name.length > 32
                        ? entry_by_route.name.slice(0, 32) + "..."
                        : entry_by_route.name
                    }}
                  </a>
                  <ButtonComponentNew
                    @click="openFullPage"
                    shortcode="f"
                    title="Open this Entry in Full Page"
                    data-test="open_full_page_button"
                    :class="$style.buttonOpenFullPage"
                  >
                    <span class="sr-only">Open Full Page</span>
                    <SquareArrowOutUpRightIcon size="20" aria-hidden="true" />
                    <!-- Open Full Page -->
                  </ButtonComponentNew>
                </div>
                <div class="flex items-center gap-2">
                  <ButtonComponentNew
                    @click="
                      _trackEvent('Navigation', 'Entry Modal', 'Close Modal'),
                        $router.replace({
                          name: 'entry',
                          params: { id: $route.params.id },
                        })
                    "
                    title="Close this Entry"
                    shortcode="esc"
                    data-test="modal_close_button"
                    :class="$style.buttonClose"
                  >
                    <span class="sr-only">Close</span>
                    <XIcon size="20" aria-hidden="true" />
                  </ButtonComponentNew>
                </div>
              </div>
            </DialogTitle>

            <div
              id="entry-modal-page"
              class="container relative mx-auto flex w-full max-w-5xl flex-col gap-4"
              data-test="entry_page"
            >
              <EntrySettings
                v-model="entry_by_route_cache"
                position="modal"
                class="!px-0"
              />
            </div>
          </DialogPanel>
        </div>
      </div>
    </Transition>
  </Dialog>
</template>

<script>
import { Analytics } from "@/components/mixins/Analytics";
import { Lazying } from "./mixins/Lazying";
import {
  ChevronRightIcon,
  SquareArrowOutUpRightIcon,
  XIcon,
} from "lucide-vue-next";

export default {
  components: {
    ChevronRightIcon,
    SquareArrowOutUpRightIcon,
    XIcon,
  },
  mixins: [Analytics, Lazying],
  watch: {
    entry_by_route: function () {
      if (this.entry_by_route) {
        this._trackEvent("Navigation", "Entry Modal", "Open Modal");
        document.title = `${this.entry_by_route.name || "Unnamed"} · Pensive`;
        this.entry_by_route_cache = this.entry_by_route;
        this.dialogIsOpen = true;
      } else {
        setTimeout(() => {
          this.dialogIsOpen = false;
        }, 400);
      }
    },
    "entry_by_route.name": function () {
      if (this.entry_by_route)
        document.title = `${this.entry_by_route.name || "Unnamed"} · Pensive`;
    },
    "$route.params.id": function () {
      this.pullData();
    },
    "$store.getters.timestamp": function () {
      this.$onIdle(() => {
        this.pullData();
      });
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    entry_by_route_before() {
      return this.entries?.find((e) => e.id == this.$route.params.id);
    },
    entry_by_route() {
      return this.entries?.find((e) => e.id == this.$route.params.id2);
    },
    entries() {
      return this.$store.getters.entries?.filter((e) => e.deleted_at === null);
    },
    defaultOutput() {
      return [{ key: "links", op: "any", val: [this.entry_by_route.id] }];
    },
    outputIsModified() {
      return (
        this.entry_by_route.output &&
        JSON.stringify(this.defaultOutput) !=
          JSON.stringify(this.entry_by_route.output.filters)
      );
    },

    linkedEntries() {
      return this.entry_by_route.links
        .map((id) => this.entries.find((e) => e.id == id))
        .filter((e) => e);
    },

    cssProps() {
      return {
        "--ps-output-display-entry-display-background-color-hover": this.$store
          .getters.dragged.length
          ? "transparent"
          : null,
      };
    },
  },
  data() {
    return {
      window: window,
      schema: null,
      showSettings: false,
      showTemplates: false,
      showEntryModal: true,
      isOpen: true,
      entry_by_route_cache: null,
      dialogIsOpen: false,

      // UX
      scrollTopOnPress: 0,
      scrollingIsMade: false,
    };
  },
  created: function () {
    this.pullData();
    if (this.entry_by_route) {
      document.title = `${this.entry_by_route.name || "Unnamed"} · Pensive`;
      this.entry_by_route_cache = this.entry_by_route;
    }
    this.dialogIsOpen = this.entry_by_route ? true : false;
  },
  methods: {
    openFullPage() {
      this._trackEvent("Navigation", "Entry Modal", "Open Full Page");

      this.$router.replace({
        name: "entry",
        params: { id: this.$route.params.id2 },
      });
    },
    updateEntry() {
      this.$store.dispatch("push", {
        event: "entry_update",
        params: { entry: this.entry_by_route },
        entry: this.entry_by_route,
      });
    },
    pullData() {
      if (this.$route.name == "entry") {
        if (this.entry_by_route?.deleted_at === null) {
          this.$store.dispatch("pull", {
            filters: [{ key: "id", op: "eq", val: this.entry_by_route.id }],
          });
        } else {
          // this.$router.push({ name: "home" });
        }
      }
    },
    slugify: function (title) {
      var slug = title.toLowerCase();
      slug = slug.replace(/\s*$/g, "");
      slug = slug.replace(/\s+/g, "-");
      return slug;
    },
  },
};
</script>

<style module lang="scss">
.buttonOpenFullPage {
  composes: button from "@/styles/buttons.module.scss";
  composes: buttonFlex from "@/styles/buttons.module.scss";
  composes: buttonRounded from "@/styles/buttons.module.scss";
  composes: buttonSecondary from "@/styles/buttons.module.scss";
  --ps-button-padding-x: 0.25rem;
  --ps-button-padding-y: 0.25rem;
  // @apply rounded-full px-2 py-1.5;
}
.buttonClose {
  composes: button from "@/styles/buttons.module.scss";
  composes: buttonFlex from "@/styles/buttons.module.scss";
  composes: buttonRounded from "@/styles/buttons.module.scss";
  composes: buttonPrimary from "@/styles/buttons.module.scss";
  --ps-button-padding-x: 0.25rem;
  --ps-button-padding-y: 0.25rem;
  // @apply rounded-full px-2 py-1.5;
}
</style>
