<template>
  <div class="flex items-center gap-x-1">
    <!--<img
        class="mx-auto h-10 w-auto"
        src="https://tailwindui.com/img/logos/mark.svg?color=gray&shade=600"
        alt="Your Company"
      />-->
    <!-- <img
      class="h-10 w-auto rounded-xl bg-white shadow-sm ring-2 ring-inset ring-neutral-900"
      src="/images/logo.svg"
      alt="Pensive"
    /> -->
    <h1 className="text-2xl font-black uppercase select-none">Pensive</h1>
  </div>
</template>

<script>
export default {
  name: "LogoComponent",
};
</script>
