<template>
  <div
    v-if="input"
    class="flex min-w-max flex-col gap-2 divide-y dark:divide-neutral-700"
    data-test="input_builder"
  >
    <!-- {{ input }} -->
    <LabelHelper> Input Settings </LabelHelper>
    <fieldset
      v-for="([key], index) in Object.entries(input.schema).filter((v) => v)"
      :key="index"
      class="flex flex-col gap-y-1.5 pt-2 first:pt-0"
    >
      <div class="relative flex items-center gap-2">
        <ButtonComponent
          @click="removeField(key)"
          data-test="input_builder_delete_row"
          size="xs"
          :color="color"
          :title="
            'Remove ' +
            key.charAt(0).toUpperCase() +
            key.slice(1) +
            ' from this Input'
          "
        >
          <XMarkIcon class="h-4 w-4" />
          {{ key.charAt(0).toUpperCase() + key.slice(1).replace("_", " ") }}
        </ButtonComponent>
        <OptionPicker
          v-if="key == 'links'"
          v-model="input.schema.links.op"
          :options="['set', 'add']"
          @update:modelValue="
            (op) => {
              input.schema.links.op = op;
              input = { ...input, ...{ schema: input.schema } };
            }
          "
          :size="size"
          :color="color"
        />
        <div
          v-if="
            $store.getters.space?.ai_enabled && aiSupportedFields.includes(key)
          "
          class="absolute right-0 top-0 flex items-center gap-2"
        >
          <ToggleComponent
            label="AI"
            :modelValue="
              input.settings?.ai &&
              typeof input.settings?.ai[key] != 'undefined'
            "
            @update:modelValue="(value) => setAiSettingsFor(key, value)"
            style="--ps-toggle-color: var(--ps-color-pink-500)"
          />
        </div>
      </div>

      <StatusSettings
        v-if="key == 'status'"
        v-model="status"
        :statuses="statuses"
        :full="true"
        :size="size"
        :variant="variant"
        :color="color"
        data-test="input_builder_status"
        class="self-start"
      />
      <div v-if="key == 'schedule'" class="flex flex-col gap-2 px-2">
        <div class="flex flex-wrap items-stretch justify-stretch gap-2">
          <LabelHelper class="flex w-10 items-center">Date:</LabelHelper>
          <SelectMenu
            v-if="!input.schema.schedule.date"
            :modelValue="null"
            @update:modelValue="
              (val) => {
                input = $merge(input, {
                  schema: { schedule: { date: val } },
                });
              }
            "
            :teleport="false"
            :options="[
              {
                title: 'No Date',
                value: null,
              },
              {
                title: 'Today',
                value: { op: 'today' },
              },
              {
                title: 'Tomorrow',
                value: { op: 'tomorrow' },
              },
              {
                title: 'Yesterday',
                value: { op: 'yesterday' },
              },
              {
                title: 'Next ...',
                value: { op: 'nextXDays' },
              },
              {
                title: 'Last ...',
                value: { op: 'lastXDays' },
              },
            ]"
            class="flex-1"
            data-test="input_builder_schedule_date"
          />
          <SelectMenu
            v-else
            v-model="input.schema.schedule.date.op"
            @update:modelValue="
              (val) => {
                if (val === null) {
                  input = $merge(input, {
                    schema: { schedule: { date: null } },
                  });
                } else {
                  input = $merge(input, {
                    schema: { schedule: { date: { op: val } } },
                  });
                }
              }
            "
            :teleport="false"
            :options="[
              {
                title: 'No Date',
                value: null,
              },
              {
                title: 'Today',
                value: 'today',
              },
              {
                title: 'Tomorrow',
                value: 'tomorrow',
              },
              {
                title: 'Yesterday',
                value: 'yesterday',
              },
              {
                title: 'Next ...',
                value: 'nextXDays',
              },
              {
                title: 'Next ...',
                value: 'nextXWeeks',
                hidden: true,
              },
              {
                title: 'Next ...',
                value: 'nextXMonths',
                hidden: true,
              },
              {
                title: 'Next ...',
                value: 'nextXYears',
                hidden: true,
              },
              {
                title: 'Last ...',
                value: 'lastXDays',
              },
              {
                title: 'Last ...',
                value: 'lastXWeeks',
                hidden: true,
              },
              {
                title: 'Last ...',
                value: 'lastXMonths',
                hidden: true,
              },
              {
                title: 'Last ...',
                value: 'lastXYears',
                hidden: true,
              },
            ]"
            class="flex-1"
            data-test="input_builder_schedule_date"
          />
          <TextInput
            v-if="input.schema.schedule.date?.op?.includes('X')"
            v-model="input.schema.schedule.date.x"
            :validation="(val) => val.replace(/[^\d]/g, '')"
            @update:modelValue="
              (val) => {
                input = $merge(input, {
                  schema: { schedule: { date: { x: val } } },
                });
              }
            "
            class="flex-1 text-sm"
          />

          <SelectMenu
            v-if="input.schema.schedule.date?.op?.includes('next')"
            v-model="input.schema.schedule.date.op"
            @update:modelValue="
              (val) => {
                input = $merge(input, {
                  schema: { schedule: { date: { op: val } } },
                });
              }
            "
            :teleport="false"
            :options="[
              {
                title: 'Days',
                value: 'nextXDays',
              },
              {
                title: 'Weeks',
                value: 'nextXWeeks',
              },
              {
                title: 'Months',
                value: 'nextXMonths',
              },
              {
                title: 'Years',
                value: 'nextXYears',
              },
            ]"
            class="flex-1"
          />

          <SelectMenu
            v-if="input.schema.schedule.date?.op?.includes('last')"
            v-model="input.schema.schedule.date.op"
            @update:modelValue="
              (val) => {
                input = $merge(input, {
                  schema: { schedule: { date: { op: val } } },
                });
              }
            "
            :teleport="false"
            :options="[
              {
                title: 'Days',
                value: 'lastXDays',
              },
              {
                title: 'Weeks',
                value: 'lastXWeeks',
              },
              {
                title: 'Months',
                value: 'lastXMonths',
              },
              {
                title: 'Years',
                value: 'lastXYears',
              },
            ]"
            class="flex-1"
          />
        </div>
        <div class="flex flex-wrap items-stretch justify-stretch gap-2">
          <LabelHelper class="flex w-10 items-center">Time:</LabelHelper>
          <SelectMenu
            v-if="!input.schema.schedule.time"
            :modelValue="null"
            @update:modelValue="
              (val) => {
                input = $merge(input, {
                  schema: { schedule: { time: val } },
                });
              }
            "
            :teleport="false"
            :options="[
              {
                title: 'No Time',
                value: null,
              },
              {
                title: 'Now',
                value: { op: 'now' },
              },
              {
                title: 'Next ...',
                value: {
                  op: 'nextXHours',
                },
              },
              {
                title: 'Last ...',
                value: {
                  op: 'lastXHours',
                },
              },
            ]"
            class="flex-1"
            data-test="input_builder_schedule_time"
          />
          <SelectMenu
            v-else
            v-model="input.schema.schedule.time.op"
            @update:modelValue="
              (val) => {
                if (val === null) {
                  input = $merge(input, {
                    schema: { schedule: { time: null } },
                  });
                } else {
                  input = $merge(input, {
                    schema: { schedule: { time: { op: val } } },
                  });
                }
              }
            "
            :teleport="false"
            :options="[
              {
                title: 'No Time',
                value: null,
              },
              {
                title: 'Now',
                value: 'now',
              },
              {
                title: 'Next ...',
                value: 'nextXHours',
              },
              {
                title: 'Next ...',
                value: 'nextXMinutes',
                hidden: true,
              },
              {
                title: 'Next ...',
                value: 'nextXSeconds',
                hidden: true,
              },
              {
                title: 'Last ...',
                value: 'lastXHours',
              },
              {
                title: 'Last ...',
                value: 'lastXMinutes',
                hidden: true,
              },
              {
                title: 'Last ...',
                value: 'lastXSeconds',
                hidden: true,
              },
            ]"
            class="flex-1"
            data-test="input_builder_schedule_time"
          />
          <TextInput
            v-if="input.schema.schedule.time?.op?.includes('X')"
            v-model="input.schema.schedule.time.x"
            :validation="(val) => val.replace(/[^\d]/g, '')"
            @update:modelValue="
              (val) => {
                input = $merge(input, {
                  schema: { schedule: { time: { x: val } } },
                });
              }
            "
            class="flex-1 text-sm"
          />

          <SelectMenu
            v-if="input.schema.schedule.time?.op?.includes('next')"
            v-model="input.schema.schedule.time.op"
            @update:modelValue="
              (val) => {
                input = $merge(input, {
                  schema: { schedule: { time: { op: val } } },
                });
              }
            "
            :teleport="false"
            :options="[
              {
                title: 'Hours',
                value: 'nextXHours',
              },
              {
                title: 'Minutes',
                value: 'nextXMinutes',
              },
              {
                title: 'Seconds',
                value: 'nextXSeconds',
              },
            ]"
            class="flex-1"
          />

          <SelectMenu
            v-if="input.schema.schedule.time?.op?.includes('last')"
            v-model="input.schema.schedule.time.op"
            @update:modelValue="
              (val) => {
                input = $merge(input, {
                  schema: { schedule: { time: { op: val } } },
                });
              }
            "
            :teleport="false"
            :options="[
              {
                title: 'Hours',
                value: 'lastXHours',
              },
              {
                title: 'Minutes',
                value: 'lastXMinutes',
              },
              {
                title: 'Seconds',
                value: 'lastXSeconds',
              },
            ]"
            class="flex-1"
          />
        </div>

        <!-- <div>
          <label for="description-source">Schedule Source:</label>
          <input
            type="radio"
            id="manual"
            value="manual"
            v-model="input.schema.schedule.source"
            @change="
              (event) => {
                input.schema.schedule.source = event.target.value;
                input = { ...input, ...{ schema: input.schema } };
              }
            "
          />
          <label for="manual">Manual</label>
          <input
            type="radio"
            id="ai"
            value="ai"
            v-model="input.schema.schedule.source"
            @change="
              (event) => {
                input.schema.schedule.source = event.target.value;
                input = { ...input, ...{ schema: input.schema } };
              }
            "
          />
          <label for="ai">AI</label>
        </div> -->
      </div>
      <div v-if="key == 'links'">
        <LinksBuilder
          v-model="input.schema.links.val"
          @update:modelValue="
            (val) => {
              input.schema.links.val = val;
              input = { ...input, ...{ schema: input.schema } };
            }
          "
          :size="size"
          :variant="variant"
          :color="color"
        />

        <div
          v-if="
            $store.getters.space?.ai_enabled &&
            typeof input.settings?.ai?.links != 'undefined'
          "
          class="mt-2 flex flex-col gap-2 rounded-md p-1 ring ring-pink-500"
        >
          <LabelHelper>
            <SparklesIcon size="16" /> Let AI choose between these:
          </LabelHelper>
          <LinksBuilder
            v-model="input.settings.ai.links.links"
            @update:modelValue="
              (val) => {
                input.settings.ai.links.links = val;
                input = { ...input, ...{ settings: input.settings } };
              }
            "
            :size="size"
            :variant="variant"
            :color="color"
          />
        </div>
      </div>
      <div v-if="key == 'description'">
        <TextInput
          v-model="input.schema.description.content"
          @update:modelValue="
            (val) => {
              input.schema.description.content = val;
              input = { ...input, ...{ schema: input.schema } };
            }
          "
          placeholder="Write a description..."
          class="w-full rounded-md p-2 text-sm ring-1 ring-inset ring-neutral-200 dark:ring-neutral-800"
        />

        <div
          v-if="
            $store.getters.space?.ai_enabled &&
            typeof input.settings?.ai?.description != 'undefined'
          "
          class="mt-2 flex flex-col gap-2 rounded-md p-1 ring ring-pink-500"
        >
          <LabelHelper><SparklesIcon size="16" /> AI Prompt:</LabelHelper>
          <TextInput
            v-model="input.settings.ai.description.prompt"
            @update:modelValue="
              (val) => {
                input.settings.ai.description.prompt = val;
                input = { ...input, ...{ settings: input.settings } };
              }
            "
            placeholder="Write a prompt to let AI generate a description..."
            class="rounded-md p-2 text-sm ring-1 ring-inset ring-neutral-200 dark:ring-neutral-800"
          />
        </div>
      </div>
      <TextInput
        v-if="key == 'priority'"
        v-model="input.schema.priority.level"
        :validation="(val) => val.replace(/[^1234]/g, '')"
        @update:modelValue="
          (val) => {
            input.schema.priority.level = val;
            input = { ...input, ...{ schema: input.schema } };
          }
        "
      />
      <TextInput
        v-if="key == 'procrastination'"
        v-model="input.schema.procrastination.count"
        :validation="(val) => val.replace(/[^\d]/g, '')"
        @update:modelValue="
          (val) => {
            input.schema.procrastination.count = val;
            input = { ...input, ...{ schema: input.schema } };
          }
        "
      />
      <div v-if="key == 'time_tracking'">
        <span class="mx-2 text-xs text-neutral-500 dark:text-neutral-600">
          Start tracking immediately
        </span>
      </div>
      <div v-if="key == 'custom_values'">
        <!-- {{ input.schema.custom_values }} -->
        <div class="flex flex-col flex-wrap gap-2">
          <div
            v-for="(customField, index) in customFields"
            :key="index"
            class="flex items-center gap-2"
          >
            <CustomFieldsDisplay
              :key="customField.id"
              :customFields="[customField]"
              :entry="{ temp: true, custom_values: input.schema.custom_values }"
              @update:customValues="
                (val) => {
                  input.schema.custom_values = val.map((cv) => {
                    return {
                      custom_field_id: cv.custom_field_id,
                      value: cv.value,
                      source: cv.source,
                    };
                  });
                  input = { ...input, ...{ schema: input.schema } };
                }
              "
            />

            <div v-if="$store.getters.space?.ai_enabled">
              <input
                type="checkbox"
                :checked="
                  input.settings?.ai &&
                  input.settings?.ai.custom_values &&
                  input.settings?.ai.custom_values[customField.id]
                "
                @change="
                  (event) => {
                    if (event.target.checked) {
                      if (!input.settings.ai.custom_values) {
                        input.settings.ai.custom_values = {};
                      }
                      input.settings.ai.custom_values[customField.id] = '';
                    } else {
                      delete input.settings.ai.custom_values[customField.id];
                      if (
                        Object.keys(input.settings.ai.custom_values).length ===
                        0
                      ) {
                        delete input.settings.ai.custom_values;
                      }
                    }
                    input = { ...input, ...{ settings: input.settings } };
                  }
                "
              />
              Ai
              <TextInput
                v-if="
                  input.settings?.ai &&
                  input.settings?.ai.custom_values &&
                  typeof input.settings?.ai.custom_values[customField.id] !=
                    'undefined'
                "
                v-model="input.settings.ai.custom_values[customField.id]"
                @update:modelValue="
                  (val) => {
                    input.settings.ai.custom_values[customField.id] = val;
                    input = { ...input, ...{ settings: input.settings } };
                  }
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="key == 'ai' && $store.getters.space?.ai_enabled"
        class="flex flex-wrap items-center gap-2"
      >
        <ButtonComponentNew
          v-for="option in aiOptions"
          :key="option.id"
          :title="option.prompt"
          @click="
            () => {
              if (!input.schema.ai.prompts.includes(option.prompt)) {
                input.schema.ai.prompts.push(option.prompt);
              } else {
                input.schema.ai.prompts = input.schema.ai.prompts.filter(
                  (p) => p !== option.prompt,
                );
              }
              input = { ...input, ...{ schema: input.schema } };
            }
          "
        >
          {{ option.title }}
          <span v-if="input.schema.ai.prompts.includes(option.prompt)">
            Y
          </span>
          <span v-else>N</span>
        </ButtonComponentNew>

        <!-- <div class="flex flex-wrap items-center gap-2">
          <LabelHelper v-for="tone in input.schema.ai.tones" :key="tone">
            {{ tone }}
          </LabelHelper>

          <SelectMenu
            v-model="newTone"
            :options="
              aiTones.filter((t) => !input.schema.ai.tones.includes(t.value))
            "
            @update:modelValue="
              (val) => {
                input.schema.ai.tones.push(val);
                input = { ...input, ...{ schema: input.schema } };
                newTone = null;
              }
            "
          />
        </div> -->
      </div>
    </fieldset>

    <div class="flex flex-col items-start gap-2 pt-2 first:pt-0">
      <ButtonComponent
        v-for="(addon, key) in availableAddons.filter((a) => !input.schema[a])"
        :id="'input_builder_add_' + addon"
        :key="key"
        @click="addFieldToInput(addon)"
        size="xs"
        :color="color"
        :title="
          'Add a ' +
          addon.charAt(0).toUpperCase() +
          addon.slice(1) +
          ' to this Input'
        "
        :data-test="'input_builder_add_' + addon"
      >
        <PlusSmallIcon class="h-4 w-4" />
        {{ addon.charAt(0).toUpperCase() + addon.slice(1).replace("_", " ") }}
      </ButtonComponent>
    </div>

    <div class="flex flex-wrap items-center gap-2 pt-2">
      <a
        v-if="position != 'dialog'"
        href="https://help.pensive.io/inputs"
        target="_blank"
        class="text-sm text-blue-500 hover:text-blue-700 hover:underline"
      >
        <QuestionMarkCircleIcon class="mr-1 inline h-4 w-4" />
        <span>Learn more about inputs</span>
      </a>
      <ButtonComponent
        @click="input = null"
        :size="size"
        class="ml-auto hover:text-red-500"
        color="secondary"
        title="Delete Input"
        data-test="entry_input_delete"
      >
        <TrashIcon class="h-4 w-4" /> <span> Delete </span>
      </ButtonComponent>
    </div>
  </div>

  <div v-else>
    <ButtonComponent
      @click="input = addonBlueprint('input', entry, this)"
      :size="size"
      :variant="variant"
      :color="color"
      title="Add Input"
      data-test="entry_input_create"
      class="w-full"
      shortcode="i"
    >
      <TextCursorInput size="20" />
      <span v-if="!['round', 'minimal'].includes(variant)"> Input </span>
      <template #help>
        <video
          src="https://help.pensive.io/videos/inputs_schema.mp4"
          autoplay
          loop
          playsinline
          class="w-96"
        ></video>
      </template>
    </ButtonComponent>
  </div>
</template>

<script setup>
import { SparklesIcon, TextCursorInput } from "lucide-vue-next";
import { addonBlueprint } from "@/addonBlueprint";
</script>
<script>
import { PlusSmallIcon, XMarkIcon } from "@heroicons/vue/24/outline";

import { defineAsyncComponent } from "vue";
import { Statusing } from "../mixins/Statusing";
import OptionPicker from "../builders/OptionPicker.vue";
import { Addon } from "../mixins/Addon";
import { Scheduling } from "../mixins/Scheduling";
import ToggleComponent from "../forms/partials/ToggleComponent.vue";

export default {
  props: {
    position: String,
    color: {
      type: String,
      default: "white",
    },
  },
  mixins: [Statusing, Scheduling, Addon],
  components: {
    LinksBuilder: defineAsyncComponent(() =>
      import("@/components/links/partials/LinksBuilder.vue"),
    ),
    TextInput: defineAsyncComponent(() =>
      import("@/components/helpers/TextInput.vue"),
    ),
    XMarkIcon,
    PlusSmallIcon,
    OptionPicker,
  },
  watch: {
    "input.schema.schedule.date": function (val) {
      if (this.input) {
        if (val && val.op && val.op.includes("X")) {
          if (!this.input.schema.schedule.date.x) {
            this.input.schema.schedule.date.x = 1;
          }
        } else if (this.input.schema?.schedule?.date) {
          delete this.input.schema.schedule.date.x;
        }
      }
    },
    "input.schema.schedule.time": function (val) {
      if (this.input) {
        if (val && val.op && val.op.includes("X")) {
          if (!this.input.schema.schedule.time.x) {
            this.input.schema.schedule.time.x = 1;
          }
        } else if (this.input.schema?.schedule?.time) {
          delete this.input.schema.schedule.time.x;
        }
      }
    },
  },
  data() {
    return {
      //completion: null,
      schedule: null,
      anchor: null,
      links: null,
      newInput: false,
      showLinkSearch: false,
      aiOptions: [
        // {
        //   title: "Add a description",
        //   prompt: "Add a description",
        // },
        // {
        //   title: "Add a priority",
        //   prompt: "Add a priority",
        // },
        // {
        //   title: "Auto schedule",
        //   prompt: "Auto schedule",
        // },
        {
          title: "Fill custom fields",
          prompt: "fill_custom_fields",
        },
        {
          title: "Add relevant context",
          prompt: "add_context",
        },
        {
          title: "Write a Post",
          prompt: "write_post",
        },
        {
          title: "Create Bullet Points",
          prompt: "create_bullet_points",
        },
      ],
      aiTones: [
        {
          title: "Persuasive",
          value: "persuasive",
          description: "Convincing and influential tone that motivates action",
        },
        {
          title: "Assertive",
          value: "assertive",
          description: "Clear, confident and direct communication style",
        },
        {
          title: "Confident",
          value: "confident",
          description: "Shows certainty and authority in the message",
        },
        {
          title: "Constructive",
          value: "constructive",
          description: "Positive and supportive tone that encourages growth",
        },
        {
          title: "Diplomatic",
          value: "diplomatic",
          description:
            "Tactful and considerate approach that maintains relationships",
        },
        {
          title: "Empathetic",
          value: "empathetic",
          description:
            "Shows understanding and compassion for others' perspectives",
        },
        {
          title: "Friendly",
          value: "friendly",
          description: "Warm and approachable communication style",
        },
        {
          title: "Inspirational",
          value: "inspirational",
          description:
            "Uplifting and motivating tone that encourages positive action",
        },
        {
          title: "More Descriptive",
          value: "more_descriptive",
          description: "Adds rich detail and vivid explanations to the content",
        },
        {
          title: "More Detailed",
          value: "more_detailed",
          description:
            "Includes comprehensive information and specific examples",
        },
        {
          title: "Formal",
          value: "formal",
          description: "Professional and structured communication style",
        },
        {
          title: "Simple",
          value: "simple",
          description: "Clear and straightforward language without complexity",
        },
        {
          title: "Direct",
          value: "direct",
          description: "Straightforward and to-the-point communication",
        },
      ],
      aiSupportedFields: ["description", "links"],
    };
  },
  methods: {
    component(addon) {
      switch (addon) {
        // case "completion":
        //   return [
        //     "OptionPicker",
        //     {
        //       options: [[0], [1]],
        //       render: (v) => (v[0] ? "completed" : "uncompleted"),
        //     },
        //   ];
        case "schedule":
          return ["DatePicker"];
      }
    },
    addFieldToInput(addon) {
      switch (addon) {
        case "status":
          this.input.schema.status = this.statuses ? this.statuses[0].id : null;
          break;
        case "schedule":
          this.input.schema.schedule = {
            date: { op: "today" },
            time: null,
          };
          break;
        case "anchor":
          this.input.schema.anchor = {
            position: { op: "append" },
          };
          break;
        case "links":
          this.input.schema.links = { op: "set", val: [] };
          break;
        case "description":
          this.input.schema.description = { content: "" };
          break;
        case "priority":
          this.input.schema.priority = { level: 2 };
          break;
        case "procrastination":
          this.input.schema.procrastination = { count: 1 };
          break;
        case "time_tracking":
          this.input.schema.time_tracking = { start_at: { op: "now" } };
          break;
        case "custom_fields":
          this.input.schema.custom_values = [];
          break;
        case "ai":
          this.input.schema.ai = {
            prompts: [],
            tones: [],
          };
          break;
      }

      this.input = {
        ...this.input,
        ...{ schema: this.input.schema },
      };

      this.newInput = false;
    },

    removeField(addon) {
      delete this.input.schema[addon];

      this.input = {
        ...this.input,
        ...{ schema: this.input.schema },
      };
    },
    setAiSettingsFor(addon, value) {
      var data = {};
      switch (addon) {
        case "links":
          data = { links: [] };
          break;
        case "description":
          data = { prompt: "" };
          break;
      }

      if (!this.input.settings) {
        this.input.settings = {};
      }

      if (value) {
        if (this.input.settings.ai == null) {
          this.input.settings.ai = {};
        }
        this.input.settings.ai[addon] = data;
      } else {
        delete this.input.settings.ai[addon];
        if (Object.keys(this.input.settings.ai).length == 0) {
          delete this.input.settings.ai;
        }
      }

      this.input = { ...this.input, ...{ settings: this.input.settings } };
    },
  },
  computed: {
    input: {
      get() {
        return this.modelValue;
      },
      set(input) {
        // console.log("setting input", input);
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.input = input;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
      },
    },
    validatedValue() {
      var value = {};
      // if (this.value.completion) value.completion = this.value.completion;
      if (this.value.status) value.status = this.value.status;
      if (this.value.schedule) value.schedule = this.value.schedule;
      if (this.value.anchor) value.anchor = this.value.anchor;
      if (this.value.links) value.links = this.value.links;
      if (this.value.description) value.description = this.value.description;
      if (this.value.priority) value.priority = this.value.priority;
      if (this.value.procrastination)
        value.procrastination = this.value.procrastination;
      return value;
    },
    entries() {
      return this.$store.getters.entries.filter((e) => e.deleted_at === null);
    },

    statuses() {
      return this.$merge(
        this.getOwnAvailableStatuses(this.entry),
        JSON.stringify(this.getOwnAvailableStatuses(this.entry)) !=
          JSON.stringify(this.getAvailableStatuses(this.entry))
          ? this.getAvailableStatuses(this.entry)
          : [],
      );
    },
    status: {
      get() {
        return this.getStatusById(this.input.schema.status);
      },
      set(status) {
        this.input = {
          ...this.input,
          ...{ schema: { ...this.input.schema, ...{ status: status?.id } } },
        };
      },
    },
    customFields() {
      return this.input.schema.links?.val
        ?.map((id) => this.$store.getters.entries.find((e) => e.id == id))
        .filter((e) => e?.custom_fields?.length)
        .flatMap((e) => e.custom_fields);
    },
    availableAddons() {
      const baseAddons = [
        "links",
        "status",
        "schedule",
        "anchor",
        "description",
        "priority",
        "procrastination",
        "time_tracking",
        "custom_fields",
      ];

      if (this.$store.getters.space?.ai_enabled) {
        baseAddons.push("ai");
      }

      return baseAddons;
    },
  },
};
</script>
