/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */

import moment from "moment/moment";

export const Grouping = {
  methods: {
    getGroups(grouping, forceGroups = false) {
      if (grouping && (['center', 'modal', 'focus', 'template'].includes(this.position) || forceGroups)) {
        switch (grouping.field) {
          case "status":
            return [
              ...this.entries.filter((e) =>
                e.status_id === null).length ? [
                {
                  key: "no_status",
                  name: "No Status",
                  filter: (e) =>
                    e.status_id === null,
                  schema: null,
                },
              ] : [],
              ...this.getOwnAvailableStatuses(this.entry).sort(this.sortByPosition(grouping.dir)).map((s) => {
                return {
                  key: this.$slugify(s.name),
                  name: s.name,
                  filter: (e) => e.status_id == s.id,
                  schema: {
                    ...this.entry.input?.schema, ...{
                      status: s.id,
                    }
                  },
                };
              }),
              ...this.entries.filter((e) => e.status_id !== null &&
                !this.getOwnAvailableStatuses(this.entry)
                  .map((s) => s.id)
                  .includes(e.status_id)).length ? [
                {
                  key: "other_status",
                  name: "Other Status",
                  filter: (e) => e.status_id !== null &&
                    !this.getOwnAvailableStatuses(this.entry)
                      .map((s) => s.id)
                      .includes(e.status_id),
                  schema: null,
                },
              ] : [],
            ];
          case "schedule":
            return [
              ...this.entries.filter((e) => e.schedule?.date == null).length ? [
                {
                  key: 'no_date',
                  name: "No date",
                  filter: (e) => e.schedule?.date == null,
                }] : [],
              ...this.entries.filter((e) => this.isOverdue(e)).length ? [
                {
                  key: 'overdue',
                  name: "Overdue",
                  filter: (e) => this.isOverdue(e),
                  actions: [
                    {
                      name: "Reschedule to today",
                      fn: (entries) => {
                        entries.forEach((e) => {
                          this.setEntrySchedule(e, {
                            date: this.interpretDate({ date: moment().format('YYYY-MM-DD') }),
                            time: e.schedule?.time,
                          });
                          !e.temp
                            ? this.$store.dispatch("push", {
                              event: "entry_update",
                              params: { entry: e },
                              entry: e,
                            })
                            : null;
                        });
                      },
                    },
                  ],
                }] : [],
              ...Array.from({ length: ['board'].includes(this.renderType) ? 7 : 3 }, (_, i) => {
                return {
                  key: i == 0 ? 'today' : (i == 1 ? 'tomorrow' : moment().add(i, 'days').format('dddd')),
                  name: i == 0 ? 'Today' : (i == 1 ? 'Tomorrow' : moment().add(i, 'days').format('dddd')),
                  filter: (e) => e.schedule && e.schedule.date && moment(e.schedule.date).isSame(moment().add(i, 'days'), 'day'),

                  schema: {
                    ...this.entry.input?.schema, ...{
                      schedule: {
                        ...this.entry.input?.schema?.schedule, ...{
                          date: moment().add(i, 'days').format('YYYY-MM-DD'),
                        }
                      },
                    }
                  },
                }
              })
            ];
          case "schedule_time":

            // var first_hour = this.entries.filter((e) => e.schedule?.time != null).sort(this.sortEntries([{ field: ['schedule', 'time'], dir: 1 }]))[0]
            // var last_hour = this.entries.filter((e) => e.schedule?.time != null).sort(this.sortEntries([{ field: ['schedule', 'time'], dir: -1 }]))[0]

            var start = 8;
            var end = 23;


            return [
              ...this.entries.filter((e) => e.schedule?.time == null).length ? [
                {
                  key: "no_time",
                  name: "No time",
                  filter: (e) => e.schedule?.time == null,
                }] : [],
              ...this.entries.filter((e) => e.schedule?.time != null && moment.utc(e.schedule.time, "HH:mm:ss").local().format("HH:00:00") < moment().hour(start).local().format("HH:00:00")).length ? [
                {
                  key: "before_" + moment().hour(start).format('HH:00'),
                  name: "Before " + moment().hour(start).format('HH:00'),
                  filter: (e) => e.schedule?.time != null && moment.utc(e.schedule.time, "HH:mm:ss").local().format("HH:00:00") < moment().hour(start).local().format("HH:00:00"),
                }] : [],
              ...Array.from({ length: end - start }, (_, i) => {
                const hour = moment().hour(start + i).utc();
                return {
                  key: hour.local().format('HH:00'),
                  name: hour.local().format('HH:00'),
                  filter: (e) => e.schedule && e.schedule.time && moment.utc(moment().format("YYYY-MM-DD") + " " + e.schedule.time).local().format("HH") == moment().hour(start + i).local().format('HH') /*moment.utc(moment().format("YYYY-MM-DD") + " " + e.schedule.time).local().isSame(moment().hour(i), 'hour') */,
                  schema: {
                    ...this.entry.input?.schema, ...{
                      schedule: {
                        ...this.entry.input?.schema?.schedule, ...{
                          time: hour.utc().format('HH:00:00'),
                        }
                      },
                    }
                  },
                }
              })
            ];
          // default:
          //   return [{ filter: () => true, schema: {} }];
        }
      }
      return [{ key: 'default', filter: () => true, schema: this.entry?.input?.schema }];
    },
    filterByGroup(group) {
      return (entry) => {
        return true;
        let type;
        if (entry.aspect) {

          type = entry.aspect.type;
        } else {
          type = 'list';
        }

        return type == group;
      }
    },
    /**
     * Returns a function that filters entries based on the given grouping.
     * @param {Object} grouping - The grouping object to filter by.
     * @returns {Function} A function that filters entries based on the given grouping.
     */
    filterGroups(grouping) {
      // Get an array of filter functions for each group in the grouping.
      const groups = this.getGroups(grouping, true).map(group => group.filter);

      // Return a function that filters entries based on whether they match any of the group filters.
      return entry => groups.filter(filter => filter(entry)).length > 0;
    }
  },
};
