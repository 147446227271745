<template>
  <ButtonComponentNew
    :class="$style.button"
    shortcode="s"
    data-test="settings_display"
  >
    <IconHelper name="settings" size="20" />
    <span v-if="variant != 'round'">Settings</span>
  </ButtonComponentNew>
</template>

<script>
import { Addon } from "../mixins/Addon";
export default {
  mixins: [Addon],
};
</script>

<style module>
.button {
  composes: button from "@/styles/buttons.module.scss";
  composes: buttonPrimary from "@/styles/buttons.module.scss";
  composes: buttonFlex from "@/styles/buttons.module.scss";
  --ps-button-padding-x: 0.625rem;
  --ps-button-padding-y: 0.375rem;
  font-size: 0.875rem;
}
</style>
