<template>
  <!-- <TextInput
        v-if="3 > 4"
        @onKeyUp="search = $event"
        class="text-sm"
        placeholder="Search Templates..."
      /> -->

  <Dialog
    as="div"
    :open="true"
    @close="$emit('close')"
    class="relative z-[999] h-px w-px"
  >
    <Transition
      @enter="animationEnterBackdrop"
      @leave="animationLeaveBackdrop"
      :css="false"
      appear
    >
      <div class="fixed inset-0 backdrop-blur" />
    </Transition>
    <Transition
      @enter="animationEnter"
      @leave="animationLeave"
      :css="true"
      appear
    >
      <div
        class="fixed inset-0 z-10 w-screen overflow-y-auto overflow-x-hidden"
        style="scrollbar-gutter: stable"
      >
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <DialogPanel
            ref="outer"
            class="relative box-content max-w-[90vw] transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-2 text-left shadow-xl ring-1 ring-transparent transition-all sm:my-8 sm:w-full sm:px-6 sm:pb-6 md:max-w-3xl lg:max-w-4xl dark:bg-black dark:ring-neutral-700"
            style="transition: height 1s cubic-bezier(0.16, 1, 0.3, 1)"
          >
            <div ref="inner" class="relative">
              <!-- note: class relative is for the template builder transition effects -->
              <DialogTitle
                as="h3"
                class="sticky top-0 z-10 -mx-4 flex justify-between rounded-b bg-white px-4 py-4 dark:bg-black"
              >
                <div class="flex items-center gap-4">
                  <ButtonComponent
                    v-if="$refs.template_builder?.selectedTemplate"
                    @click="$refs.template_builder.selectedTemplate = null"
                    color="secondary"
                    size="sm"
                    class="!px-1"
                  >
                    <IconHelper name="arrow-left" size="20" />
                  </ButtonComponent>
                  <span class="text-xl font-semibold">{{
                    //$refs.template_builder?.selectedTemplate?.name ||
                    "Templates"
                  }}</span>
                </div>
                <ButtonComponent
                  @click="$emit('close')"
                  color="secondary"
                  size="sm"
                  class="!px-1"
                >
                  <IconHelper name="x" size="20" />
                </ButtonComponent>
              </DialogTitle>

              <TemplateBuilder ref="template_builder" :modelValue="entry" />
            </div>
          </DialogPanel>
        </div>
      </div>
    </Transition>
  </Dialog>
</template>

<script>
// import TemplateBuilder from "../builders/TemplateBuilder.vue";

import { Applicators } from "../mixins/Applicators";
import { Scheduling } from "../mixins/Scheduling";
import { Schema } from "../mixins/Schema";
import { Statusing } from "../mixins/Statusing";
import { Templating } from "../mixins/Templating";
import TextInput from "./TextInput.vue";
import TemplateBuilder from "../builders/TemplateBuilder.vue";

export default {
  // inject: ["position"],
  props: {
    modelValue: Object,
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    TextInput,
    TemplateBuilder,
  },
  mixins: [Schema, Applicators, Statusing, Scheduling, Templating],
  data() {
    return {
      search: "",
    };
  },
  mounted() {
    this.$store.dispatch("retrieveTemplates");

    this.$nextTick(() => {
      this.initResizeObserver();
    });
  },

  beforeUnmount() {
    this.cleanupResizeObserver();
  },
  computed: {
    entry: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    initResizeObserver() {
      this.resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const newHeight = entry.contentRect.height;
          if (this.$refs.outer)
            this.$refs.outer.$el.style.height = `${newHeight}px`;
        }
      });
      // if (this.$refs.inner)
      this.resizeObserver.observe(this.$refs.inner);
    },
    cleanupResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
    },
    animationEnterBackdrop(el, done) {
      this.$anime({
        duration: 300,
        easing: "linear",
        targets: el,
        opacity: [0, 1],
        complete: done,
      });
    },
    animationLeaveBackdrop(el, done) {
      this.$anime({
        duration: 300,
        easing: "linear",
        targets: el,
        opacity: [1, 0],
        complete: done,
      });
    },
    animationEnter(el, done) {
      this.$anime({
        targets: el,
        opacity: [0, 1],
        translateY: [200, 0],
        complete: done,
      });
    },
    animationLeave(el, done) {
      this.$anime({
        targets: el,
        opacity: [1, 0],
        translateY: [0, 200],
        complete: done,
      });
    },
  },
};
</script>
