<template>
  <div :class="$style.timeTrackingDisplay">
    <TransitionGroupHelper>
      <div key="settings">
        <PopoverHelper :teleport="true" placement="bottom-start">
          <template v-slot:button>
            <div
              :class="$style.popoverButton"
              data-test="time_trackings_display_action_settings"
            >
              <TimerIcon size="14" />
              <div>
                <TransitionGroupHelper
                  :enter="{ translateY: [50, 0], opacity: [0, 1] }"
                  :leave="{ translateY: [0, -50], opacity: [1, 0] }"
                >
                  <div
                    v-if="!userHasInEntryActiveTimeTracking"
                    variant="minimal"
                    size="sm"
                  >
                    <span data-test="time_trackings_display_total_time">
                      {{ totalTrackedTime }}
                    </span>
                  </div>

                  <div
                    v-if="
                      activeTimeTrackingByUser &&
                      activeTimeTrackingByUser.entry_id == entry.id
                    "
                  >
                    <span
                      v-if="!entry.temp"
                      data-test="time_trackings_display_active_time"
                    >
                      {{
                        getDurationOfTimeTracking(
                          activeTimeTrackingByUser,
                        ).format("hh:mm:ss", {
                          stopTrim: "m",
                        })
                      }}
                    </span>
                    <span
                      v-else
                      title="Upon creation, this entry will immediately start tracking"
                    >
                      <PlayIcon size="14" :class="$style.playIconAnimation" />
                    </span>
                  </div>
                </TransitionGroupHelper>
              </div>
            </div>
          </template>

          <div v-if="!entry.temp" :class="$style.popoverContent">
            <SectionHeader url="https://help.pensive.io/time-trackings">
              <template #title>
                <TimerIcon size="16" /> Time Trackings
              </template>
            </SectionHeader>
            <TimeTrackingsWrapper
              v-model="time_trackings"
              :entry="entry"
              class="min-w-64 overflow-y-auto"
            />
          </div>
        </PopoverHelper>
      </div>

      <div v-if="!entry.temp" key="toggle" class="flex items-center">
        <button
          v-if="
            !activelyTimeTrackedEntry || activelyTimeTrackedEntry.id != entry.id
          "
          @click.stop="() => startTimeTracking(entry)"
          title="Start time tracking"
          data-test="time_trackings_display_action_start"
        >
          <IconHelper name="play" size="14" />
        </button>
        <button
          v-if="
            activelyTimeTrackedEntry && activelyTimeTrackedEntry.id == entry.id
          "
          type="button"
          ref="reference"
          @click.stop="() => stopTimeTracking(entry)"
          data-test="time_trackings_display_action_stop"
          title="Stop time tracking"
        >
          <PauseIcon size="14" />
        </button>
      </div>
    </TransitionGroupHelper>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import SectionHeader from "../helpers/SectionHeader.vue";
import TimeTrackingsWrapper from "./partials/TimeTrackingsWrapper.vue";

import { TimerIcon, PlayIcon, PauseIcon } from "lucide-vue-next";
import { TimeTrackingsMixin } from "./mixins/TimeTrackingsMixin";
import { Tracking } from "../mixins/Tracking";

export default {
  mixins: [TimeTrackingsMixin, Tracking],
  props: {
    modelValue: Object,
    entry: Object,
  },
  computed: {
    time_trackings: {
      get() {
        return this.modelValue;
      },
      set(time_trackings) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.time_trackings = time_trackings;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
      },
    },
    user() {
      return this.$store.state.user;
    },
    userHasActiveTimeTracking() {
      return this.user.time_trackings.find(
        (tt) => tt.user_id == this.user.id && !tt.end_at,
      );
    },
    userHasInEntryActiveTimeTracking() {
      if (!this.user) return null;
      return this.entry.time_trackings.find(
        (tt) => tt.user_id == this.user.id && !tt.end_at,
      );
    },
    totalTrackedTime() {
      return this.$moment
        .utc(
          this.time_trackings
            // ?.filter((tt) => tt.end_at !== null)
            .map((tt) => {
              const startTime = this.$moment.utc(tt.start_at);
              const endTime = tt.end_at
                ? this.$moment.utc(tt.end_at)
                : this.$moment.utc();

              if (!startTime.isValid() || !endTime.isValid()) return 0;
              return endTime.unix() - startTime.unix();
            })
            .reduce((a, b) => a + b, 0) * 1000,
        )
        .format("H:mm:ss");
    },
  },
  components: {
    SectionHeader,
    TimerIcon,
    TimeTrackingsWrapper,
    PlayIcon,
    PauseIcon,
  },
};
</script>

<style module lang="scss">
.timeTrackingDisplay {
  composes: entrySubRow from "@/styles/entries.module.scss";
}

.popoverButton {
  @apply flex items-center gap-1;
  color: var(--ps-color-neutral-500);
  font-variant-numeric: tabular-nums;
}

.popoverContent {
  composes: panelWrapper from "@/styles/panels.module.scss";
  width: 20rem;
}

.playIconAnimation {
  animation: spin 2s ease-in-out infinite;
}

@keyframes spin {
  50% {
    transform: translateX(0.25rem);
  }
}

@container calendarEventContent (width < 480px) {
  .timeTrackingDisplay {
    display: none;
  }
}
@container calendarEventContent (height < 48px) {
  .timeTrackingDisplay {
    display: none;
  }
}
</style>
