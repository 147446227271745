<template>
  <div
    v-if="schema"
    @click="editing = true"
    :class="{
      [$style.inputDisplay]: true,
      // 'rounded-lg px-2 py-1.5': !['gallery'].includes(renderType),
      // 'hover:bg-neutral-50 dark:hover:bg-neutral-950':
      //   !['gallery'].includes(renderType) && !disabled,
      [$style.inputDisplay_disabled]: disabled,
      // '-mx-2 -my-1.5 ': position != 'left' && !['gallery'].includes(renderType),
      [$style.inputDisplay_editing]: editing,
    }"
    data-test="entry_input_section"
  >
    <div class="min-h-8">
      <!-- This is for reseting the keyboard suggestions on IPhone and maybe other mobile devices-->
      <input
        type="text"
        ref="mobile-suggestion-fix"
        class="absolute h-0 w-0 opacity-0"
      />
      <!-- {{ newEntry }} -->
      <EntryDisplay
        ref="entry_display"
        :modelValue="newEntry"
        :placeholder="
          '&plus; ' +
          (entry && entry.name
            ? entry.name.length > 40
              ? entry.name.slice(0, 37) + '...'
              : entry.name
            : 'Add Entry')
        "
        :display="display"
        :renderType="renderType"
        :output="output"
        :position="position"
        :disabled="entryDisplayIsDisabled"
        :editable="position != 'template'"
        @onNameKeyUp="(name) => (searchQuery = name)"
        @onNameChanged="null /*createEntry*/"
        @onFocus="(editing = true), (isFocusOnName = true)"
        @onBlur="isFocusOnName = false"
        @click="editing = true"
        :data-input="entry.id"
        :data-test="$slugify(entry?.name) + '_input'"
        data-tour="input_display"
      >
        <template #settings>
          <TourHelper
            v-if="
              entry.id &&
              entry.id == $route.params.id &&
              editable &&
              position == 'center'
            "
            hook="input_settings"
            size="sm"
            variant="minimal"
            class="mr-1.5 mt-0.5 inline-block aspect-square h-5"
          />

          <PopoverHelper
            v-if="editable"
            id="input_builder"
            data-test="input_builder"
            class="mt-0.5 inline-block"
            :teleport="true"
          >
            <template v-slot:button>
              <span
                class="w-5 text-neutral-400 hover:text-black dark:text-neutral-600 dark:hover:text-neutral-300"
                data-tour="input_settings"
              >
                <IconHelper name="settings-2" size="20" />
              </span>
            </template>

            <div class="flex flex-col gap-4 p-3">
              <div
                v-if="Object.keys(overrides).length > 0"
                class="rounded-md bg-blue-50 p-4"
              >
                <div class="flex">
                  <div class="flex-shrink-0">
                    <InformationCircleIcon
                      class="h-5 w-5 text-blue-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-sm text-blue-700">
                      The following fields have been overridden:

                      <span
                        v-for="key in Object.keys(overrides)"
                        :key="key"
                        class="text-xs font-bold uppercase"
                      >
                        {{ key }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <InputSettings
                id="input_builder_inner"
                v-model="input"
                :entry="entry"
                @click.stop
              />
            </div>
          </PopoverHelper>
        </template>
      </EntryDisplay>

      <div
        ref="reference"
        v-if="editing"
        :class="$style.toolBar"
        class="input_display_toolbar"
      >
        <Transition
          enter-active-class="duration-200 ease-out delay-1000"
          enter-from-class="transform opacity-0 translate-x-2"
          enter-to-class="opacity-100 translate-x-0"
          leave-active-class="duration-200 ease-in"
          leave-from-class="opacity-100 translate-x-0"
          leave-to-class="transform opacity-0 translate-x-2"
        >
          <ButtonComponentNew
            v-if="isSearchAvailable && searchResults.length"
            @click="showSearch = !showSearch"
            title="Show existing entries"
            shortcode="s"
            :shortcodeMeta="true"
            :class="$style.searchButton"
          >
            <SearchIcon size="16" />
            <span class="text-xs">
              <span class="font-bold">{{ searchResults.length }}</span> Entries
              found
            </span>
          </ButtonComponentNew>
        </Transition>
        <!-- {{ entriesToCreateInContext }} -->
        <ButtonComponentNew
          @click.stop="resetEntry(), (editing = false), $emit('onCancel')"
          :class="$style.cancelButton"
          title="Cancel"
          shortcode="Escape"
        >
          Cancel
        </ButtonComponentNew>
        <ButtonComponentNew
          @click.stop="
            (e) => {
              createEntry(searchQuery);
              if (
                (e?.isTrusted || $Cypress) &&
                (!aiIsRequired || aiIsApplied)
              ) {
                editing = false;
              }
            }
          "
          :shortcode="!showSearch && isFocusOnName ? 'Enter' : null"
          title="Create Entry"
          :class="$style.createButton"
          data-test="input_display_create_button"
        >
          <span
            v-if="aiIsRequired && !aiIsApplied"
            class="flex items-center gap-1"
          >
            <template v-if="aiStatus === 'idle'">
              <SparklesIcon size="16" /> AI
            </template>
            <template v-if="aiStatus === 'loading'">
              <LoaderCircleIcon size="16" class="animate-spin" /> Loading
            </template>
            <template v-else-if="aiStatus === 'error'">
              <span class="flex items-center gap-0.5 text-red-500">
                <ExclamationCircleIcon size="16" /> Error
              </span>
            </template>
          </span>
          <span v-else class="flex items-center gap-1">
            <template v-if="aiIsRequired">
              <SparklesIcon size="16" />
            </template>
            Add Entry
          </span>
        </ButtonComponentNew>
      </div>
    </div>
    <Teleport to="body">
      <Transition
        enter-active-class="duration-200 ease-out"
        enter-from-class="transform opacity-0 translate-y-2"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="transform opacity-0 translate-y-2"
      >
        <div v-if="showSearch" class="absolute">
          <OverlayComponent @click="showSearch = false" />
          <SearchResults
            ref="search_results"
            :query="searchQuery"
            :limit="5"
            :displayFields="{
              status: true,
              procrastination: false,
              priority: true,
              name: true,
              links: true,
              description: false,
              schedule: false,
              output: false,
              input: false,
              settings: false,
              routine: false,
              time_trackings: false,
              custom_fields: false,
            }"
            @onEntrySelect="handleOnEntrySelect"
            @onSearchResultsChange="currentSearchResults = $event"
            class="max-h-64 w-64 overflow-y-auto rounded-lg bg-white px-2 shadow dark:bg-black dark:shadow-none dark:ring-1 dark:ring-neutral-700"
            :style="floatingStyles"
          />
        </div>
      </Transition>
    </Teleport>
  </div>
  <div v-else>
    <ButtonComponent
      @click="input = addonBlueprint('input', entry, this)"
      data-test="entry_input_create"
      class="w-full"
    >
      <IconHelper name="TextCursorInput" size="20" />
      <span> Add Input </span>
    </ButtonComponent>
  </div>
</template>

<script setup>
import { ref } from "vue";
// eslint-disable-next-line no-unused-vars
import { autoUpdate, useFloating } from "@floating-ui/vue";

const reference = ref(null);
const search_results = ref(null);

const { floatingStyles } = useFloating(reference, search_results, {
  placement: "bottom-start",
  whileElementsMounted: autoUpdate,
  middleware: [],
});
</script>

<script>
import { addonBlueprint } from "@/addonBlueprint";
import moment from "moment";
import { nanoid } from "nanoid";
import { Schema } from "../mixins/Schema";
import { Scheduling } from "../mixins/Scheduling";
import { Statusing } from "../mixins/Statusing";
import { InformationCircleIcon } from "@heroicons/vue/24/outline";
import { SearchIcon, LoaderCircleIcon, SparklesIcon } from "lucide-vue-next";
import { Searching } from "../mixins/Searching";
import { Applicators } from "../mixins/Applicators";
import { DragAndDrop } from "../mixins/DragAndDrop";
import { TimeTrackingsMixin } from "../timetrackings/mixins/TimeTrackingsMixin";
import { ExclamationCircleIcon } from "@heroicons/vue/24/outline";
import { Filtering } from "../mixins/Filtering";

export default {
  inject: {
    overrideEntries: {
      default: null,
    },
  },
  props: {
    focusOnMount: Boolean,
    modelValue: Object,
    entry: Object,
    output: Object,
    schema: {
      type: Object,
      default: () => {
        return {};
      },
    },
    editable: {
      type: Boolean,
      default: true,
    },
    disabled: Boolean,
    display: Object,
    position: {
      type: String,
      default: () => {
        return "center";
      },
    },
    renderType: {
      type: String,
      default: () => {
        return "list";
      },
    },
    color: {
      type: String,
      default: () => {
        return "neutral";
      },
    },
  },
  mixins: [
    Schema,
    Statusing,
    Scheduling,
    Searching,
    Applicators,
    DragAndDrop,
    TimeTrackingsMixin,
    Filtering, // To be able to filter the current search to show the number in the search button
  ],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SearchIcon,
    InformationCircleIcon,
    LoaderCircleIcon,
    SparklesIcon,
    ExclamationCircleIcon,
  },
  data() {
    return {
      editing: false,
      isFocusOnName: false,
      newEntry: this.inputSchemaWithId(this.schema),
      scheduleTimer: null,
      focusIndex: 0,
      registeredInputId: null,
      addonBlueprint: addonBlueprint,

      // SEARCH RELATED

      searchPullDataTimeout: null,
      showSearch: false,
      searchQuery: "",
      // searchFocusIndex: -1,
      currentSearchResults: [],

      // AI RELATED
      aiIsRequired: false,
      aiStatus: "idle",
      aiIsApplied: false,
      entriesToCreateInContext: [],
    };
  },
  computed: {
    input: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
    overrides() {
      const overrides = {};
      Object.keys(this.schema).forEach((key) => {
        if (
          JSON.stringify(this.schema[key]) !==
          JSON.stringify(this.entry.input.schema[key])
        ) {
          overrides[key] = this.schema[key];
        }
      });
      return overrides;
    },
    entryDisplayIsDisabled() {
      return this.position == "template";
    },
    isSearchAvailable() {
      return this.position != "left" && this.searchQuery.length > 2;
    },
    searchResults() {
      if (this.isSearchAvailable) {
        return this.filterEntries([
          { key: "name", op: "any", val: this.searchQuery.toLowerCase() },
        ]);
      }
      return [];
    },
  },
  mounted: function () {
    this.manageInputRegister();

    this.checkIfAiIsRequired();

    if (this.focusOnMount)
      setTimeout(() => {
        this.editing = true;
      }, 10);
  },
  beforeUnmount() {
    this.manageInputRegister(false);
  },
  watch: {
    newEntry: function (n, o) {
      if (n.id != o.id) {
        this.checkIfAiIsRequired();
      }
    },
    // eslint-disable-next-line no-unused-vars
    searchQuery: function (n) {
      this.aiIsApplied = false;
      this.aiStatus = "idle";
      if (n.length > 0) {
        if (this.searchPullDataTimeout)
          clearTimeout(this.searchPullDataTimeout);
        this.searchPullDataTimeout = setTimeout(() => {
          this.pullData();
        }, 2000);
      } else {
        if (this.searchPullDataTimeout)
          clearTimeout(this.searchPullDataTimeout);
      }
    },
    isSearchAvailable: function (n) {
      if (n === false) this.showSearch = false;
    },
    space: function () {
      this.newEntry = this.inputSchemaWithId(this.schema);
    },
    "$route.params.id": {
      handler(n, o) {
        if (n != o) {
          this.resetEntry();
        }
      },
      immediate: true,
    },
    editing: function (n) {
      // Select the input field when editing
      if (n) {
        if (this.editing && this.disabled) {
          this.editing = false;
        }
        this.$store.getters.registeredInputs.forEach((input) => {
          if (input.entry.id != this.entry?.id) {
            input.obj.editing = false;
          }
        });
        this.$nextTick(() => {
          if (this.editing) {
            this.$el.querySelector("[contenteditable='true']")?.focus();
            this.$el.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        });
      } else {
        // Blur any active selection when editing is turned off
        window.getSelection()?.removeAllRanges();
        // Remove focus from any active element
        document.activeElement?.blur();
      }
    },
    // entries: function () {
    //   this.newEntry = this.inputSchemaWithId(this.schema);
    // },
    entry: function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        // console.log("entry watcher editing false", o, n);
        // this.editing = false;
        this.newEntry = this.inputSchemaWithId(this.schema);
        this.manageInputRegister();
      }
    },
    input: function () {
      if (this.input) {
        this.newEntry = this.inputSchemaWithId(this.schema);
      }
    },
    schema: {
      handler() {
        // if (JSON.stringify(n) != JSON.stringify(o)) {
        this.newEntry = this.inputSchemaWithId(this.schema);
        // }
      },
      deep: true,
    },
    // "$store.getters.clock": function () {
    //   this.scheduleUpdater();
    // },
    // showSearch: function (n) {
    //   console.log("showSearch", n);
    // },
    // searchFocusIndex: function (n, o) {
    //   /**
    //    * We want to bring the new focused
    //    */
    //   this.$nextTick(() => {
    //     const element =
    //       this.$refs.search_results?.$refs.output?.$refs["entry_" + n];
    //     if (element && element[0]) {
    //       element[0].scrollIntoView({
    //         /**
    //          * If the difference between the old and new index is 1, scroll smoothly.
    //          * Otherwise, we move fast fro top to bottom or vice versa and just jump to the element.
    //          */
    //         behavior: Math.abs(n - o) == 1 ? "smooth" : "auto",
    //         block: "center",
    //         inline: "center",
    //       });
    //     }
    //   });
    // },
  },
  methods: {
    pullData() {
      this.$store.dispatch("pull", {
        filters: [
          { key: "name", op: "any", val: this.searchQuery.toLowerCase() },
        ],
        source: {
          file: "InputDisplay",
          entry: this.entry,
        },
      });
    },
    // onKeyDown(e) {
    //   switch (e.key) {
    //     case "Tab":
    //     case "ArrowDown":
    //       e.preventDefault();
    //       e.stopPropagation();
    //       this.incrementFocusIndex();
    //       break;
    //     case "ArrowUp":
    //       e.preventDefault();
    //       e.stopPropagation();
    //       this.decrementFocusIndex();
    //       break;
    //   }
    // },
    // incrementFocusIndex() {
    //   if (this.currentSearchResults.length) {
    //     // If the search focus index is less than the length of the search results
    //     if (this.searchFocusIndex < this.currentSearchResults.length - 1) {
    //       // Increment the search focus index
    //       this.searchFocusIndex++;
    //     } else {
    //       // Otherwise set the search focus index to 0
    //       this.searchFocusIndex = 0;
    //     }
    //   }
    // },
    // decrementFocusIndex() {
    //   if (this.currentSearchResults.length) {
    //     // If the search focus index is greater than 0
    //     if (this.searchFocusIndex > 0 ? -1 : 0) {
    //       // Decrement the search focus index
    //       this.searchFocusIndex--;
    //     } else {
    //       // Otherwise set the search focus index to the length of the search results
    //       this.searchFocusIndex = this.currentSearchResults.length - 1;
    //     }
    //   }
    // },
    // handleOnEntryNameKeyDown(event) {
    //   this.onKeyDown(event);
    // },
    // handleOnEntryNameKeyUp(event, name) {
    //   if (event.key == "Enter") {
    //     if (this.searchFocusIndex >= 0) {
    //       this.handleOnEntrySelect(
    //         this.currentSearchResults[this.searchFocusIndex],
    //       );
    //     } else {
    //       this.createEntry(name);
    //     }
    //   } else {
    //     this.searchQuery = name;
    //   }
    // },
    // eslint-disable-next-line no-unused-vars
    // handleOnNameKeyUp(name, event) {
    //   // if (event.key == "Enter") {
    //   //   if (!this.showSearch) {
    //   //     this.createEntry(name);
    //   //   }
    //   // } else {
    //   this.searchQuery = name;
    //   // }
    // },
    handleOnEntrySelect(entry) {
      this.applyInput(this.inputSchema(this.schema, entry), entry, true);
      // this.newEntry.name = entry.name;
      this.resetEntry();
    },
    clearNameInputTextField() {
      {
        if (
          this.$refs.entry_display?.$refs.name_display?.$refs.text_input.$refs
            .input
        ) {
          // console.log("InputDisplay::resetEntry");
          this.$refs.entry_display.$refs.name_display.$refs.text_input.$refs.input.innerText =
            "";
        }
      }
    },
    createEntry(name) {
      if (this.aiIsRequired && !this.aiIsApplied && this.aiStatus == "idle") {
        this.retrieveSuggestions();
        return;
      }
      if (this.aiStatus == "loading") {
        this.$store.dispatch("abortApiCall");
      }

      const regex =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

      if (this.editing && regex.test(navigator.userAgent)) {
        /**
         * This is a hack to fix the issue where
         * the keyboard suggestions do not reset
         * on mobile devices after creating an entry.
         */
        const focus = this.$el.querySelector('[contenteditable="true"]:focus');
        if (focus) {
          this.$refs["mobile-suggestion-fix"].focus();
          // setTimeout(() => {
          this.$el.querySelector('[contenteditable="true"]')?.focus();
          this.$el.scrollIntoView({ behavior: "smooth", block: "center" });
          // }, 200);
        }
      }

      const newEntry = JSON.parse(JSON.stringify(this.newEntry));

      delete newEntry.temp;
      newEntry.name = name;
      newEntry.created_at = moment().utc().format("YYYY-MM-DD HH:mm:ss");

      if (newEntry.time_trackings.length) newEntry.time_trackings = [];

      const emitEntry = { ...newEntry };

      // Seriously I like the push more, but its anti-pattern
      this.$store.dispatch("addEntry", newEntry);
      // console.log("newEntry", newEntry.name);
      // this.$store.getters.entries.push(this.newEntry);
      // console.log(this.newEntry);
      this.$store.dispatch("push", {
        event: "entry_create",
        params: { entry: newEntry, skip_ai: this.aiIsApplied },
        entry: newEntry,
        // cb: () => this.$emit("created", emitEntry),
      });

      if (
        this.schema.time_tracking &&
        this.schema.time_tracking.start_at?.op &&
        this.schema.time_tracking.start_at.op == "now"
      )
        this.startTimeTracking(newEntry);

      this.resetEntry();
      this.$emit("created", emitEntry);

      this.entriesToCreateInContext.forEach((entry) => {
        delete entry.temp;
        entry.links.push(newEntry.id);
        this.$store.dispatch("addEntry", entry);
        this.$store.dispatch("push", {
          event: "entry_create",
          params: { entry: entry },
          entry: entry,
        });
      });

      // In case the input is on the left side, we want to close the input after creating an entry
      if (this.position == "left") {
        this.editing = false;
        this.showSearch = false;
      } else {
        this.$nextTick(() => {
          if (this.editing) {
            this.$el.scrollIntoView({ behavior: "smooth", block: "center" });
            this.$el.querySelector('[contenteditable="true"]')?.focus();
          }
        });
      }
    },
    resetEntry() {
      this.newEntry = this.inputSchemaWithId(this.schema);

      this.clearNameInputTextField();
      setTimeout(() => {
        /**
         * This is a hack to fix the issue where
         * the input field does not clear after creating an entry.
         * This is only an issue on mobile devices.
         */
        this.clearNameInputTextField();
      }, 0);

      this.searchQuery = "";
      this.showSearch = false;
    },
    /**
     * Updates the schedule of the new entry based on the schema's schedule.
     * If the schema has a schedule date and time, it interprets them and sets them on the new entry.
     */
    scheduleUpdater() {
      // Check if the schema and new entry have a schedule
      if (this.schema?.schedule && this.newEntry?.schedule) {
        // Check if the schema has a schedule date and the new entry has a schedule date
        if (this.schema.schedule.date?.op && this.newEntry.schedule.date) {
          // Interpret the date from the schema and set it on the new entry
          this.newEntry.schedule.date = this.interpretDate(
            this.schema.schedule,
          );
        }
        // Check if the schema has a schedule time and the new entry has a schedule time
        if (this.schema.schedule.time?.op && this.newEntry.schedule.time) {
          // Interpret the time from the schema and set it on the new entry
          this.newEntry.schedule.time = this.interpretTime(
            this.schema.schedule,
          );
        }
      }

      // if (this.schema?.time_tracking && this.newEntry?.time_trackings) {
      //   console.log("hi");
      //   if (
      //     this.schema.time_tracking.start_at?.op &&
      //     this.newEntry.time_trackings.length == 1
      //   ) {
      //     this.newEntry.time_trackings[0].start_at = this.$moment
      //       .utc()
      //       .format("YYYY-MM-DD HH:mm:ss");
      //     console.log(this.newEntry.time_trackings[0].start_at);
      //   }
      // }
    },
    onInputUpdate() {
      this.newEntry = this.inputSchemaWithId(this.schema);
    },
    inputSchemaWithId(schema) {
      const base = {
        temp: true,
        id: nanoid(),
        space_id: this.space?.id || this.$store.getters.user?.current_space_id,
        user_id: this.$store.getters.user?.id,
        input_id: this.input?.id,
        status_id: null,
        name: null,
        description: null,
        statuses: [],
        custom_fields: [],
        custom_values: [],
        time_trackings: [],
        senses: [],
        links: [],
        backlinks: [],
        created_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        updated_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        completed_at: null,
        deleted_at: null,
      };
      return {
        ...base,
        ...this.inputSchema(schema, base),
      };
    },
    manageInputRegister(add = true) {
      // if (this.entry.id) {
      if (this.registeredInputId) {
        this.$store.dispatch("unregisterInput", {
          id: this.registeredInputId,
          el: this.$el,
          entry: this.entry,
          schema: this.schema,
          position: this.position,
        });
        this.registeredInputId = null;
      }
      if (add) {
        this.registeredInputId = this.$nanoid();
        this.$store.dispatch("registerInput", {
          id: this.registeredInputId,
          el: this.$el,
          obj: this,
          entry: this.entry,
          schema: this.schema,
          position: this.position,
        });
      }
      // }
    },
    checkIfAiIsRequired() {
      this.aiStatus = "idle";
      this.aiIsRequired =
        this.$store.getters.space?.ai_enabled &&
        this.$store.getters.space?.ai_requests_count_this_month <
          this.$store.getters.space?.ai_requests_quota &&
        this.input.settings?.ai &&
        Object.values(this.input.settings?.ai).length;
      this.aiIsApplied = false;
    },
    retrieveSuggestions() {
      this.aiStatus = "loading";
      this.$store
        .dispatch("retrieveEntriesInContext", {
          entry: {
            ...this.newEntry,
            ...{ name: this.searchQuery },
          },
          input: this.input,
          output: this.output,
        })
        .then((data) => {
          if (data && data.length) {
            data.forEach((entry) => {
              if (entry.id == this.newEntry.id)
                this.newEntry = { ...this.newEntry, ...entry };
            });

            data = data.filter((entry) => entry.id != this.newEntry.id);
            this.entriesToCreateInContext = data;
            this.aiStatus = "idle";
            this.aiIsApplied = true;
          }
        })
        .catch(() => {
          this.aiStatus = "error";
        });
    },
  },
};
</script>

<style module>
.inputDisplay {
  @apply relative flex cursor-pointer flex-col gap-y-2;
  border-radius: var(--ps-entry-display-border-radius);
}

.inputDisplay_disabled {
  @apply !cursor-default;
}
.inputDisplay_editing {
  background-color: var(--ps-base-tinted-color);
  outline: 1px solid var(--ps-base-border-color);
  outline-offset: -1px;
}

.toolBar {
  @apply flex justify-end gap-x-4 p-2;
  border-top: 1px solid var(--ps-base-border-color);
}

.searchButton {
  composes: button from "@/styles/buttons.module.scss";
  composes: buttonFlex from "@/styles/buttons.module.scss";

  --ps-button-color: var(--ps-base-secondary-color);
  --ps-button-color-hover: var(--ps-base-primary-color);
  --ps-button-bg-color: transparent;
  --ps-button-bg-color-hover: transparent;

  /* composes: buttonBordered from "@/styles/buttons.module.scss"; */
  /* composes: buttonSecondary from "@/styles/buttons.module.scss"; */
  /* composes: buttonRounded from "@/styles/buttons.module.scss"; */

  margin-right: auto;

  > span {
    /* display: block;
    background-color: var(--ps-base-tertiary-color);
    min-width: 1.25rem;
    padding: 0.125rem;
    border-radius: 0.5rem;
    color: var(--ps-base-background-color);
    font-size: var(--ps-font-size-xs);
    line-height: var(--ps-line-height-xs); */
  }
}

.cancelButton {
  composes: buttonSecondary from "@/styles/buttons.module.scss";
  --ps-button-padding-x: 0px;
  --ps-button-padding-y: 0px;
  --ps-button-font-size: var(--ps-font-size-sm);
  --ps-button-bg-color: transparent;
  --ps-button-bg-color-hover: transparent;
}

.createButton {
  composes: buttonFlex from "@/styles/buttons.module.scss";
  composes: buttonPrimary from "@/styles/buttons.module.scss";
  --ps-button-padding-x: 10px;
  --ps-button-padding-y: 6px;
  --ps-button-font-size: var(--ps-font-size-sm);
}
</style>
