<template>
  <div
    class="flex min-h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8"
  >
    <!-- bg-gradient-to-br from-neutral-100 to-neutral-200 dark:from-neutral-800 dark:to-neutral-900 -->
    <div
      class="z-10 flex flex-col items-center sm:mx-auto sm:w-full sm:max-w-md"
    >
      <Logo />
      <h2
        class="mt-2 text-center text-2xl font-bold leading-9 tracking-tight text-neutral-900 dark:text-neutral-300"
      >
        <SmoothText :text="subheading_text" align="center" />
      </h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
      <!-- <Transition
        :appear="true"
        @enter="animationEnterOfAuthPanel"
        :css="false"
      > -->
      <PanelHelper
        ref="outer"
        class="box-content !p-12"
        style="transition: height 0.3s cubic-bezier(0.16, 1, 0.3, 1)"
      >
        <div ref="inner">
          <!-- h-px w-px rounded-full  transition-all !duration-1000 mx-auto  -->

          <!-- <ConstructionArea v-else class="mb-5" /> -->
          <!-- <LoginForm key="login" v-if="$route.name == 'login'" />
            <RegisterForm key="register" v-if="$route.name == 'register'" />-->

          <form v-if="isMountedAndAnimated" @submit.prevent>
            <TransitionGroupHelper
              :appear="true"
              :enter="{
                opacity: [0, 1],
                translateY: [50, 0],
                delay: (el) => el.dataset.delay,
              }"
            >
              <AlertHelper
                key="message"
                v-if="message"
                :variant="message.type"
                class="mb-4"
              >
                {{ message.message }}
              </AlertHelper>

              <InputWithLabel
                v-for="(field, index) in formFields"
                :key="field.key"
                :label="field.label"
                :type="field.type"
                :placeholder="field.placeholder"
                v-model="formData[field.key]"
                class="mb-5"
                :data-delay="index * 100"
                :required="true"
              />

              <div
                v-if="$route.name == 'login'"
                key="forgot-password"
                class="mb-5 flex items-center justify-between"
                data-delay="300"
              >
                <div class="text-sm leading-6">
                  <router-link
                    :to="{ name: 'forgot-password' }"
                    class="font-semibold text-neutral-600 hover:text-neutral-500 dark:text-neutral-500 dark:hover:text-neutral-400"
                  >
                    Forgot password?
                  </router-link>
                </div>
              </div>
              <div v-if="buttonText" key="button" data-delay="500">
                <button
                  @click="handleButtonClick"
                  type="submit"
                  :disabled="loading"
                  class="mb-5 flex w-full justify-center rounded-md bg-neutral-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-neutral-950 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-600 disabled:bg-neutral-700 dark:bg-neutral-800 dark:hover:bg-neutral-600 dark:disabled:bg-neutral-900"
                  data-test="auth_button"
                >
                  <span v-if="loading">
                    <IconHelper
                      name="loader-circle"
                      size="24"
                      class="animate-spin"
                    />
                  </span>
                  <SmoothText v-else :text="buttonText" />
                </button>
              </div>
              <SocialLoginButtons
                v-if="['login', 'register'].includes($route.name)"
                key="social"
                data-delay="600"
                :class="{ 'pointer-events-none': loading }"
              />
            </TransitionGroupHelper>
          </form>
        </div>
      </PanelHelper>
      <!--</Transition>-->
      <p
        v-if="$route.name == 'login'"
        class="mt-10 text-center text-sm text-neutral-500"
      >
        Not a member?
        <router-link
          :to="{ name: 'register' }"
          class="font-semibold leading-6 text-neutral-600 hover:text-neutral-950 dark:text-neutral-400 dark:hover:text-neutral-300"
        >
          Sign up for free
        </router-link>
      </p>
      <p
        v-if="$route.name == 'register'"
        class="mt-10 text-center text-sm text-neutral-500"
      >
        Already a member?
        <router-link
          :to="{ name: 'login' }"
          class="font-semibold leading-6 text-neutral-600 hover:text-neutral-950 dark:text-neutral-400 dark:hover:text-neutral-300"
        >
          Login
        </router-link>
      </p>

      <p
        v-if="
          $route.name == 'forgot-password' || $route.name == 'reset-password'
        "
        class="mt-10 text-center text-sm text-neutral-500"
      >
        Know your password?
        <router-link
          :to="{ name: 'login' }"
          class="font-semibold leading-6 text-neutral-600 hover:text-neutral-950 dark:text-neutral-400 dark:hover:text-neutral-300"
        >
          Login instead
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import Logo from "../Logo.vue";
import { Auth } from "../mixins/Auth";
import { Analytics } from "../mixins/Analytics";
import SocialLoginButtons from "./SocialLoginButtons.vue";

export default {
  mixins: [Auth, Analytics],
  components: {
    SocialLoginButtons,
    Logo,
  },
  data() {
    return {
      formData: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      isMountedAndAnimated: true,
    };
  },
  watch: {
    "$route.name": function () {
      this.message = null;
    },
  },
  mounted() {
    // if (this.$enableAnalytics) {
    //   window._paq.push(["setDocumentTitle", this.$route.meta.title]);
    //   window._paq.push(["trackPageView"]);
    // }
    this.initResizeObserver();

    if (this.$route.name == "email-verification") {
      this.verifyEmail();
    }
  },

  beforeUnmount() {
    this.cleanupResizeObserver();
  },
  methods: {
    initResizeObserver() {
      this.resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const newHeight = entry.contentRect.height;
          // console.log("Height changed:", newHeight);
          // this.$anime({
          //   targets: this.$refs.outer,
          //   height: newHeight,
          //   easing: "easeOutExpo",
          // });
          if (this.$refs.outer)
            this.$refs.outer.$el.style.height = `${newHeight}px`;
        }
      });
      this.resizeObserver.observe(this.$refs.inner);
    },
    cleanupResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
    },
    animationEnterOfAuthPanel(el, done) {
      this.$anime({
        targets: el,
        opacity: [0, 1],
        translateY: [50, 0],
        begin: () => {
          setTimeout(() => {
            this.isMountedAndAnimated = true;
            // el.classList.remove("rounded-full");
            // el.classList.remove("w-px");
            // el.classList.add("w-auto");
            // el.classList.remove("aspect-square");
          }, 0);
        },
        complete: () => {
          done();

          // setTimeout(() => {
          // this.isMountedAndAnimated = true;
          // el.classList.remove("rounded-full");
          // el.classList.remove("w-px");
          // el.classList.add("w-auto");
          // el.classList.remove("aspect-square");
          // }, 200);
        },
      });
    },
    handleButtonClick() {
      this.message = null;
      switch (this.$route.name) {
        case "login":
          this.login();
          break;
        case "register":
          this.register();
          break;
        case "forgot-password":
          this.forgot_password();
          break;
        case "reset-password":
          this.reset_password();
          break;
        case "email-verification":
          this.$router.push({ name: "login" });
          break;
      }
    },
    verifyEmail() {
      const id = this.$route.params.verification_id;
      const hash = this.$route.params.verification_hash;

      if (id && hash) {
        this.$store
          .dispatch("verifyEmail", {
            id: id,
            hash: hash,
            expires: this.$route.query.expires,
            signature: this.$route.query.signature,
          })
          .then(() => {
            this.$store.dispatch("retrieveUser");
            this.message = {
              type: "success",
              message: "Email successfully verified.",
            };
            this.success = true;
          })
          .catch((data) => {
            if (data.response?.data?.message == "Invalid signature.") {
              this.message = {
                type: "warning",
                message: "This link is expired, please send yourself a new one",
              };
            } else {
              this.message = {
                type: "error",
                message: "An error appeared",
              };
            }

            this.resend = true;
          });
      }
    },
  },
  computed: {
    subheading_text() {
      switch (this.$route.name) {
        case "login":
          return "Sign in to your account";
        case "register":
          return "Create your account";
        case "forgot-password":
          return "Reset your password";
        case "reset-password":
          return "Reset your password";
        case "email-verification":
          return "Email Verification";
        default:
          return "";
      }
    },
    buttonText() {
      switch (this.$route.name) {
        case "login":
          return "Sign in";
        case "register":
          return "Sign up";
        case "forgot-password":
          return "Reset password";
        case "reset-password":
          return "Reset password";
        case "email-verification":
          return "Go back";
        default:
          return "";
      }
    },
    formFields() {
      if (!this.isMountedAndAnimated) return [];
      switch (this.$route.name) {
        case "login":
          return [
            {
              label: "Email",
              key: "email",
              type: "email",
              autocomplete: "email",
            },
            {
              label: "Password",
              key: "password",
              type: "password",
              autocomplete: "current-password",
            },
          ];
        case "register":
          return [
            {
              label: "First Name",
              key: "firstname",
              autocomplete: "given-name",
            },
            {
              label: "Last Name",
              key: "lastname",
              autocomplete: "family-name",
            },
            {
              label: "Email",
              key: "email",
              type: "email",
              autocomplete: "email",
            },
            {
              label: "Password",
              key: "password",
              type: "password",
              autocomplete: "current-password",
            },
            {
              label: "Confirm Password",
              key: "password_confirmation",
              type: "password",
              autocomplete: "current-password",
            },
          ];
        case "forgot-password":
          return [
            {
              label: "Email",
              key: "email",
              type: "email",
              autocomplete: "email",
            },
          ];
        case "reset-password":
          return [
            {
              label: "Password",
              key: "password",
              type: "password",
            },
            {
              label: "Confirm Password",
              key: "password_confirmation",
              type: "password",
            },
          ];
        default:
          return [];
      }
    },
  },
};
</script>
