<template>
  <div class="flex flex-col gap-2">
    <input
      ref="searchInput"
      v-model="query"
      type="text"
      placeholder="Search entries..."
      :class="$style.searchInput"
      data-test="send_to_search"
    />

    <SearchResults
      :query="query"
      :filtersOnEmpty="[{ key: 'anchor', op: 'is set' }]"
      :filterSearchResults="(e) => e.input"
      @onEntrySelect="handleSendToSelect"
      @onSearchResultsChange="currentSearchResults = $event"
      class="max-h-64 overflow-y-auto overflow-x-hidden px-2"
      data-test="send_to_search_results"
    />
  </div>
</template>

<script>
import { Applicators } from "@/components/mixins/Applicators";
import { Filtering } from "@/components/mixins/Filtering";
import { Searching } from "@/components/mixins/Searching";
import { Statusing } from "@/components/mixins/Statusing";
import { Scheduling } from "@/components/mixins/Scheduling";
import { Schema } from "@/components/mixins/Schema";

export default {
  mixins: [Schema, Statusing, Scheduling, Applicators, Filtering, Searching],
  props: {
    entry: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      query: "",
      currentSearchResults: [],
    };
  },
  methods: {
    handleSendToSelect(selectedEntry) {
      if (!selectedEntry.input) return;

      // Apply the selected entry's input schema to current entry
      this.applyInput(
        {
          ...this.inputSchema(selectedEntry.input.schema, this.entry),
          input_id: selectedEntry.input.id,
        },
        this.entry,
        true,
      );

      // Emit close event to close the popover
      this.$emit("close");
    },
  },
  mounted() {
    this.$refs.searchInput.focus();
  },
};
</script>

<style module>
.searchInput {
  composes: textInput from "@/styles/form.module.scss";
  /* @apply w-full rounded-md border-0 px-3 py-1.5 text-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-neutral-500 dark:bg-black dark:ring-neutral-700 dark:placeholder:text-neutral-600; */
}
</style>
