/* eslint-disable no-unreachable */

import { throttle } from "lodash";

const DRAG_DISTANCE = 25;
// const EDGE_DISTANCE = 5;

export const DragAndDrop = {
    props: {
        dropSchema: Object,
        dropIndex: Number,

        disableEdgeDetection: Boolean,
    },
    data() {
        return {
            hoverParams: null,
            mousePositionOnDragStart: { x: null, y: null },
            mouseTarget: null,
            disableClick: false,


            touchTimeout: null,
            renderDnDBeforeEdge: false,
            renderDnDAfterEdge: false,

            throttledOnMouseMove: null,
        }
    },
    computed: {
        internalSchema() {
            if (this.dropSchema) {
                return this.dropSchema;
            } else if (this.entry?.input?.schema) {
                return { ...this.entry.input.schema, input_id: this.entry.input.id };
            }
            return null;
        },
        draggable() {
            return (!this.entry || !this.entry.temp) &&
                !(this.position == 'center' && this.$route.params.id == this.modelValue?.id) &&
                !(this.position == 'modal' && this.$route.params.id2 == this.modelValue?.id) &&
                this.position != 'contextmenu' &&
                this.position != 'template' &&
                this.position != 'command-palette'
        },
        DndInfo() {
            return {
                id: this.entry.id,
                schema: this.entry.input?.schema
            }
        },
    },
    methods: {
        setSchema() {
            // console.log("setSchema", this.entry.name)
            this.$store.dispatch("schema", this.internalSchema);
        },
        // eslint-disable-next-line no-unused-vars
        onMouseEnter(e, params) {

            if (!this.draggable) return;

            if (this.$store.getters.dragged.map((entry) => entry.id).includes(params.id)) return;
            this.$store.dispatch("mouseTarget", { el: e.target, obj: this, params: params });

            this.hoverParams = params;
            this.mouseTarget = e.target;
            if (params.schema && (this.containingOutputGroup == null || !this.$store.getters.draggedBL.includes(this.containingOutputGroup))) {
                this.$store.dispatch("hovered", params);
                this.$store.dispatch('schema', params.schema);
            }
            // document.addEventListener('mousemove', this.onMouseMove)
            // document.addEventListener('touchmove', this.onMouseMove)
        },
        // eslint-disable-next-line no-unused-vars
        onTouchStart(e) {
            if (!this.draggable) return;
            // console.log("onTouchStart", this.entry.name);

            this.touchTimeout = setTimeout(() => {
                e.preventDefault();
                // console.log("onTouchStartDragStart")

                this.onMouseEnter(e, this.DndInfo);
                this.onMouseDown(e);
                this.onDragStart(e);

            }, 500);


            document.addEventListener("touchmove", this.onTouchMove, { passive: false });
            document.addEventListener("touchend", this.onTouchEnd);
            document.addEventListener("touchcancel", this.onTouchEnd);

        },
        // eslint-disable-next-line no-unused-vars
        onTouchEnd(e) {
            if (!this.draggable) return;
            // console.log("onTouchEnd");
            clearTimeout(this.touchTimeout);
            document.removeEventListener("touchmove", this.onTouchMove);
            document.removeEventListener("touchend", this.onTouchEnd);
            document.removeEventListener("touchcancel", this.onTouchEnd);
        },
        // eslint-disable-next-line no-unused-vars
        onTouchMove(e) {
            if (!this.draggable) return;
            if (this.touchTimeout)
                clearTimeout(this.touchTimeout);
            if (this.$store.getters.dragged.length) {
                e.preventDefault();
                if (e.touches && e.touches.length) e = e.touches[0];
                var element = document.elementFromPoint(e.clientX, e.clientY);
                if (element) {
                    if (!element.dataset.hasMouseenter) {
                        element = element.closest('[data-has-mouseenter]');
                    }
                    if (element) {
                        if (element != this.mouseTarget) {
                            if (this.mouseTarget) {
                                this.mouseTarget.dispatchEvent(new Event('mouseleave', { bubbles: true }));
                            }
                            element.dispatchEvent(new Event('mouseenter', { bubbles: true }));
                            this.mouseTarget = element;
                        }
                    }
                }
            }
        },
        getParent(element) {
            let parent = element.parentElement;
            while (parent) {
                if (parent.getAttribute('data-has-mouseenter')) {
                    parent.dispatchEvent(new Event('mouseenter', { bubbles: true }));
                    break;
                }
                parent = parent.parentElement;
            }
            return parent;
        },
        // eslint-disable-next-line no-unused-vars
        onMouseLeave(e) {

            // console.log("onMouseLeave", this.entry?.name)
            if (!this.draggable) return;

            if (this.mousePositionOnDragStart.x !== null && this.mousePositionOnDragStart.y !== null)
                return;
            this.mouseTarget = null;
            this.$store.dispatch("mouseTarget", {});
            this.$store.dispatch('schema', null);

            const parent = this.getParent(e.target);
            if (parent) {
                parent.dispatchEvent(new Event('mouseenter', { bubbles: true }));
            }

            // document.removeEventListener('mousemove', this.onMouseMove)
            // document.removeEventListener('touchmove', this.onMouseMove)
        },
        onMouseDown(e) {
            if (!this.draggable) return;


            if (!this.throttledOnMouseMove)
                this.throttledOnMouseMove = throttle(this.onMouseMove, 100)

            if (e.type == "mousedown") {
                /**
                 * If the user is holding shift, we don't want to start the drag, because
                 * the user might want to select multiple entries at once.
                 * Also, if the user is clicking on a text field, we don't want to start the drag
                 * Also, if the user is clicking on the search bar, we don't want to start the drag
                 */
                if (!e.shiftKey && e.target.contentEditable != "true" && !['search'].includes(this.position)) {
                    if (!this.draggable) return;
                    if (e.button === 0) { // check if left mouse button was clicked
                        this.mousePositionOnDragStart = { x: e.clientX, y: e.clientY }

                        document.addEventListener('mousemove', this.throttledOnMouseMove)
                        document.addEventListener('touchmove', this.throttledOnMouseMove)

                        document.addEventListener('mouseup', this.onMouseUp)
                        document.addEventListener('touchend', this.onMouseUp)
                        document.addEventListener('touchcancel', this.onMouseUp)
                    }
                }
            } else if (e.type == "touchstart") {
                document.addEventListener('mousemove', this.throttledOnMouseMove)
                document.addEventListener('touchmove', this.throttledOnMouseMove)
                document.addEventListener('mouseup', this.onMouseUp)
                document.addEventListener('touchend', this.onMouseUp)
                document.addEventListener('touchcancel', this.onMouseUp)
            }

        },
        onMouseMove(e) {
            // console.log("onMouseMove", this.entry.name)
            if (!this.draggable) return;

            // e.stopPropagation(); ! @note this will make the wrapper not follow the mouse

            if (this.mousePositionOnDragStart.x !== null && this.mousePositionOnDragStart.y !== null) {
                const dx = e.clientX - this.mousePositionOnDragStart.x
                const dy = e.clientY - this.mousePositionOnDragStart.y
                if (Math.abs(dx) > DRAG_DISTANCE || Math.abs(dy) > DRAG_DISTANCE) {
                    this.onDragStart(e)
                }
            }

            // if (this.$el) {
            //     if (typeof this.$el.getBoundingClientRect !== 'function') return;

            //     const rect = this.$el.getBoundingClientRect();
            //     const x = e.clientX - rect.left;
            //     const y = e.clientY - rect.top;
            //     const onEdge = y < EDGE_DISTANCE || y > rect.height - EDGE_DISTANCE || x < EDGE_DISTANCE || x > rect.width - EDGE_DISTANCE;
            //     onEdge;
            //     // console.log(onEdge, this.entry?.name)
            // }

            //     // eslint-disable-n
            // if (this.$store.getters.hovered?.id == this.hoverParams?.id) {
            //     const rect = this.mouseTarget.getBoundingClientRect();
            //     const x = e.clientX - rect.left;
            //     const y = e.clientY - rect.top;
            //     const onEdge = y < EDGE_DISTANCE || y > rect.height - EDGE_DISTANCE || x < EDGE_DISTANCE || x > rect.width - EDGE_DISTANCE;
            //     // eslint-disable-next-line no-constant-condition
            //     if (false && onEdge && this.parent?.input?.schema) {
            //         this.$store.dispatch('schema', this.parent.input.schema);
            //     } else {
            //         if (this.$store.getters.draggedBL?.includes(this.hoverParams.id)) {
            //             // this.$store.dispatch('schema', null);
            //         } else {
            //             // this.$store.dispatch('schema', this.hoverParams.schema);
            //         }
            //     }
            // }
        },
        // eslint-disable-next-line no-unused-vars
        onDragStart(e) {
            if (!this.draggable) return;
            // if (this.position == 'left') return;

            // console.log("onDragStart", this.entry.name)

            document.removeEventListener('mousemove', this.throttledOnMouseMove)
            document.removeEventListener('touchmove', this.throttledOnMouseMove)


            // blur all text selections
            window.getSelection().removeAllRanges();
            // blur all focused elements
            document.activeElement.blur();


            this.$store.dispatch("hovered", []);
            this.$store.dispatch('schema', null)

            this.mousePositionOnDragStart = { x: null, y: null }
            this.disableClick = true;

            // add dragged to store
            this.$store.dispatch("dragged", this.$store.getters.selected.length ? this.$store.getters.selected.map((id) =>
                this.$store.getters.entries.find((entry) => entry.id == id),
            ) : [this.entry]);

            this.$store.dispatch("draggedBL", [this.containingOutputGroup]);

        },
        // eslint-disable-next-line no-unused-vars
        onMouseUp(e) {

            if (this.entry.temp)
                return;

            // console.log("onMouseUp", this.entry.name)
            /**
             * If the user is holding shift, we don't want to stop the drag, because
             * the user might want to drag multiple entries at once.
             */

            if (!e.shiftKey) {
                if (!this.draggable) return;
                if (this.$store.getters.schema && this.$store.getters.dragged.length) {
                    this.$store.getters.dragged?.forEach((entry) => {
                        const schema = this.inputSchema(this.$store.getters.schema, entry)
                        if (this.inputApplyable(schema, entry)) {
                            this.applyInput(schema, entry, true);
                        }
                    });
                    this.$store.dispatch('schema', null)
                    // note: this will unselect the dragged entries after the schema has been applied 
                    if (this.$store.getters.selected.length)
                        this.$store.dispatch('selected', null)
                }

                this.mousePositionOnDragStart = { x: null, y: null }

                document.removeEventListener('mousemove', this.throttledOnMouseMove)
                document.removeEventListener('touchmove', this.throttledOnMouseMove)
                document.removeEventListener('mouseup', this.onMouseUp)
                document.removeEventListener('touchend', this.onMouseUp)
                document.removeEventListener('touchcancel', this.onMouseUp)

                if (this.$store.getters.dragged.length)
                    this.$store.dispatch("dragged", []);

                setTimeout(() => {
                    this.disableClick = false
                }, 250)
            }
        }
    }
};
